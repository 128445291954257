// ** React Import
import { useState, useMemo, MouseEvent, Fragment } from "react";

// ** MUI Import
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

// ** Types import
import {
  StakingDashboardFilter,
  IStakingDashboardFilterOpt,
} from "../../pages/StakingDashboardPage/types";

// ** Css import
import "./styles.css";
import { HawkIcons } from "../../assets";

const poolFilterOpts: IStakingDashboardFilterOpt[] = [
  {
    label: "Hightest TVL",
    value: "HIGHTEST_TVL",
  },
  {
    label: "Hightest APR",
    value: "HIGHTEST_APR",
  },
];

type HawkStakingDashboardFilterProps = {
  activeFilter: StakingDashboardFilter;
  handleChangeFilter: (filter: StakingDashboardFilter) => void;
};

const HawkStakingDashboardFilter = ({
  activeFilter,
  handleChangeFilter,
}: HawkStakingDashboardFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openFilterMenu = Boolean(anchorEl);

  const activeFilterOpt = useMemo(() => {
    const _filter = poolFilterOpts.find(
      (filter) => filter.value === activeFilter
    );

    return _filter;
  }, [activeFilter]);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeFilterOpt = async (filter: StakingDashboardFilter) => {
    try {
      await handleChangeFilter(filter);
      handleCloseMenu();
    } catch (error) {}
  };

  return (
    <Fragment>
      <Box
        id="dashboard-active-filter-box"
        component="button"
        aria-haspopup="true"
        aria-expanded={openFilterMenu ? "true" : undefined}
        aria-controls={
          openFilterMenu ? "dashboard-filter-opts-menu" : undefined
        }
        className="dashboard-active-filter-box"
        onClick={handleOpenMenu}
      >
        {activeFilterOpt ? (
          <Fragment>
            <Typography className="dashboard-filter-label">
              {activeFilterOpt.label}
            </Typography>
            <img
              alt=""
              src={HawkIcons.CaretDownIcon}
              className={`toggle-menu-icon ${
                openFilterMenu ? "rotate-icon" : ""
              }`}
            />
          </Fragment>
        ) : (
          <Typography className="dashboard-filter-label">
            Select a filter
          </Typography>
        )}
      </Box>
      <Menu
        id="dashboard-filter-opts-menu"
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              backgroundImage: "none",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "dashboard-active-filter-box",
          style: {
            padding: 0,
            paddingTop: "4px",
            background: "transparent",
          },
        }}
      >
        <Box className="dashboard-filter-opts-container">
          {poolFilterOpts.map((opt) => {
            const isSelectedOpt = opt.value === activeFilter;
            return (
              <Box
                component="div"
                className={
                  isSelectedOpt ? "active-dashboard-filter-opt" : "dashboard-filter-opt"
                }
                onClick={() => handleChangeFilterOpt(opt.value)}
              >
                <Typography className="dashboard-filter-label">
                  {opt.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Menu>
    </Fragment>
  );
};

export default HawkStakingDashboardFilter;
