// ** MUI import
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "0.5rem",
  width: "100%",
  borderRadius: "6.25rem",
  backgroundColor: "#2b2b35",
}));

type ProgressProps = {
  progress: number;
  height?: number | string;
  width?: number | string;
};

const HawkProgress = ({ progress, height }: ProgressProps) => {
  return (
    <CustomLinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: { height },
        "& .MuiLinearProgress-bar": {
          background: "rgba(200, 255, 0, 0.3)",
        },
      }}
    />
  );
};

const WhiteLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "4px",
  width: "100%",
  borderRadius: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
}));

export const HawkWhiteProgress = ({ progress, height }: ProgressProps) => {
  return (
    <WhiteLinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: { height },
        "& .MuiLinearProgress-bar": {
          background: "rgba(255, 255, 255, 1)",
        },
      }}
    />
  );
};

const BlueYellowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  width: "100%",
  backgroundColor: "rgba(0, 154, 249, 1)",
}));

export const HawkBlueYellowProgress = ({
  progress,
  height,
  width,
}: ProgressProps) => {
  return (
    <BlueYellowLinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: { height },
        width: { width },
        "& .MuiLinearProgress-bar": {
          background: "rgba(255, 197, 8, 1)",
        },
      }}
    />
  );
};

const YellowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "8px",
  width: "100%",
  borderRadius: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
}));

export const HawkYellowProgress = ({
  progress,
  height,
  width,
}: ProgressProps) => {
  return (
    <YellowLinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: { height },
        width: { width },
        "& .MuiLinearProgress-bar": {
          borderRadius: "8px",
          background: "rgba(255, 197, 8, 1)",
        },
      }}
    />
  );
};

const RainbowLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "16px",
  width: "100%",
  borderRadius: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
}));

export const HawkRainbowProgress = ({
  progress,
  height,
  width,
}: ProgressProps) => {
  return (
    <RainbowLinearProgress
      variant="determinate"
      value={progress}
      sx={{
        height: { height },
        width: { width },
        "& .MuiLinearProgress-bar": {
          borderRadius: "8px",
          background:
            "linear-gradient(90deg, #D30054 0%, #FFC508 35.5%, #7CC937 65.5%, #009AF9 100%)",
        },
      }}
    />
  );
};

export default HawkProgress;
