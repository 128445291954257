import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
interface EarningsPool {
  pool: string;
  amount: string | number;
}
const Earnings = () => {
  const tempData = [
    { pool: "1", amount: "1" },
    { pool: "2", amount: "0" },
    { pool: "3", amount: "0" },
    { pool: "4", amount: "0" },
  ];
  return (
    <Box>
      <Typography className="table-title-text">Earnings</Typography>
      <TableContainer component={Paper} className="table-style">
        <Table>
          <TableHead>
            <TableRow className="background-table-header">
              <TableCell className="inactive-text-color">Pool</TableCell>
              <TableCell className="inactive-text-color">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tempData &&
              tempData.map((item: EarningsPool) => (
                <TableRow className="background-table-row">
                  <TableCell className="hide-border-bottom table-body-value-text">
                    #{item.pool}
                  </TableCell>
                  <TableCell className="hide-border-bottom table-body-value-text">
                    {item.amount}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default Earnings;
