// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import StackBox from "./components/StackBox";
import Earnings from "./components/Earnings";
import YieldTopPerformers from "./components/YieldTopPerformers";

import EmptyStake from "./components/Empty";

import { renderIfElse } from "../../helpers/render-helper";

import { StakingDashboardFilter } from "./types";

import HawkStakingDashboardFilter from "../../components/HawkStakingDashboardFilter";

// ** Css import
import "./index.css";

const StakingDashboardPage = () => {
  const [dashboardFilter, setDashboardFilter] = useState<StakingDashboardFilter>('HIGHTEST_TVL')

  const handleChangeDashboardFilter = (filter: StakingDashboardFilter) => {
    setDashboardFilter(filter)
  }

  return (
    <Box className="staking-dashboard-page">
      <Box className="dashboard-container">
        <Box className="stack-container">
          <Box className="stacking-dashboard-header-row">
            <Box className="dashboard-summary-info-box">
              <Typography className="total-stack">Total Staked: $0</Typography>
              <Typography className="claimable-text">Claimable: $0</Typography>
              <HawkStakingDashboardFilter activeFilter={dashboardFilter} handleChangeFilter={handleChangeDashboardFilter} />
            </Box>
          </Box>
          {renderIfElse(
            false,
            <EmptyStake />,
            <Box className="stack-box-container">
              <StackBox />
              <StackBox />
              <StackBox />
            </Box>
          )}
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: "rgba(29, 31, 20, 1)" }}
        />
        <Box className="side-container">
          <Box sx={{ flex: 1 }}>
            <Earnings />
          </Box>
          <Box sx={{ flex: 1 }}>
            <YieldTopPerformers />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StakingDashboardPage;
