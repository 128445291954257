// ** React import
import { useMemo } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { Wallet } from "lucide-react";

// ** Types import
import { IHawkStep } from ".";

// ** Css import
import "./styles.css";

type HawkStepCardProps = {
  step: IHawkStep;
};

const HawkStepCard = ({ step }: HawkStepCardProps) => {
  const { containerClass, actionLabel, actionBtnClass, dividerClass } =
    useMemo(() => {
      const containerClass = step.activeStep
        ? "hawk-active-step-card-container"
        : "hawk-step-card-container";

      const actionBtnClass = step.activeStep
        ? "hawk-active-step-action-btn"
        : "hawk-step-action-btn";

      const dividerClass = step.activeStep
        ? "hawk-active-step-divider"
        : "hawk-step-divider";

      const actionLabel = step.activeStep ? step.actionLabel : "Waiting...";

      return {
        actionLabel,
        dividerClass,
        actionBtnClass,
        containerClass,
      };
    }, [step]);

  return (
    <Box className={containerClass}>
      <Box className="hawk-step-card-content">
        <Wallet width={40} height={40} />
        <Typography className="hawk-active-step-name">{step.name}</Typography>
        <Divider
          flexItem
          variant="fullWidth"
          orientation="horizontal"
          className={dividerClass}
        />
        <Typography className="hawk-active-step-description">
          {step.description}
        </Typography>
      </Box>
      <Box className={actionBtnClass}>
        <Typography className="hawk-step-action-text">{actionLabel}</Typography>
      </Box>
    </Box>
  );
};

export default HawkStepCard;
