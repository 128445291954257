import type {} from "@mui/material/themeCssVarsAugmentation";
import { ThemeOptions } from "@mui/material/styles";

import { PaletteMode } from "@mui/material";

import {
  brown,
  blueGrey,
  red,
  green,
  blue,
  orange,
} from "@mui/material/colors";

import "./fonts.css";

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode: mode,
    background: {
      paper: "rgb(16,18,33)",
      default: "rgb(0,0,1)",
    },
    primary: {
      main: "rgb(200, 255, 0)",
    },
    secondary: {
      main: blueGrey[500],
    },
    text: {
      primary: "rgb(255,255,255)",
      secondary: "rgba(0,0,0,0.6)",
      disabled: "rgba(0,0,0,0.38)",
      success: green[500],
      warning: orange[500],
      info: blue[500],
      error: red[500],
      brown: brown[700],
      contract: "#0e0e10",
    },
    divider: "rgb(150, 183, 16)",
  },
  typography: {
    fontFamily: ['"Space Grotesk", "sans-serif"'].join(","),
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    // https://mui.com/material-ui/customization/transitions/#easings
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
});

export default function HawkTheme(mode: PaletteMode): ThemeOptions {
  return {
    ...getDesignTokens(mode),
  };
}
