// ** React import
import { Outlet } from "react-router-dom";

// ** MUI import
import Box from "@mui/material/Box";

// ** Components import
import HawkAppBar from "../components/HawkAppBar";
import HawkFooter from "../components/HawkFooter";

// ** Css import
import "./styles.css";

const MainLayout = () => {
  return (
    <Box className="page-container">
      <HawkAppBar />
      <Outlet />
      <HawkFooter />
    </Box>
  );
};

export default MainLayout;
