// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import HawkInoHeader from "../../components/HawkInoHeader";
import HawkInoIntroduce from "../../components/HawkInoIntroduction";

// ** Css import
import "./styles.css";

// ** Fake Data import
import { FakeIno } from "./data";

const IdoDetailPage = () => {
  return (
    <Box className="ino-detail-page-container">
      <HawkInoHeader ino={FakeIno} />
      <HawkInoIntroduce ino={FakeIno} />
    </Box>
  );
};

export default IdoDetailPage;
