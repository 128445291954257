// ** React import
import { useState, useCallback } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import HawkWalletAssets from "../HawkWalletAssets";

// ** Types import
import { IFakeHawkPoolMetadata } from "../../pages/StakingPoolsPage/types";

// ** Helpers import
import { onlyUnique, renderIfElse } from "../../helpers/render-helper";

// ** Assets Import
import { HawkIcons } from "../../assets";

import Spinner from "./SpinnerGap.svg";

// ** Css Import
import "./styles.css";

type HawkStakingDialogProps = {
  open: boolean;
  pool: IFakeHawkPoolMetadata;
  handleClose: () => void;
};

const HawkStakingDialog = ({
  open,
  pool,
  handleClose,
}: HawkStakingDialogProps) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([2]);

  const handleToggleSelectItem = useCallback(
    (id: number) => {
      if (selectedItems.includes(id)) {
        const _newItems = selectedItems.filter((item) => item !== id);
        setSelectedItems(_newItems);
      } else {
        setSelectedItems((prev) => {
          return [...prev, id];
        });
      }
    },
    [selectedItems]
  );

  const handleSelectItems = (ids: number[]) => {
    setSelectedItems((prev) => {
      const _newItems = [...prev, ...ids];

      return _newItems.filter(onlyUnique);
    });
  };

  const handleRemoveAllItem = () => {
    setSelectedItems([]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{
        style: {
          backgroundColor: "rgba(14, 15, 10, 1)",
          backgroundImage: "none",
          boxShadow: "none",
        },
      }}
    >
      <Box className="hawk-staking-dialog-container">
        <img
          onClick={handleClose}
          src={HawkIcons.CloseIcon}
          alt="close icon"
          className="staking-dialog-close-icon"
        />
        <Box className="staking-pool-title-header">
          <Box className="staking-pool-header-img-box">
            <LazyLoadImage
              src={pool.image}
              alt="pool image"
              effect="blur"
              className="staking-pool-header-img"
            />
            <Box className="staking-pool-detail-image-overlay" />
          </Box>
          <Box className="staking-pool-title-info-box">
            <Typography className="staking-pool-name">{pool.name}</Typography>
            <Typography className="staking-pool-description">
              {pool.description}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="horizontal"
          variant="middle"
          className="staking-dialog-horizontal-divider"
        />
        <Box className="staking-dialog-content">
          <Box sx={{ flex: 1 }}>
            <HawkWalletAssets
              selectedItems={selectedItems}
              handleSelectItems={handleSelectItems}
              handleToggleSelectItem={handleToggleSelectItem}
              handleRemoveAllItem={handleRemoveAllItem}
            />
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="staking-dialog-vertical-divider"
          />
          <Box className="staking-dialog-checkout-card">
            <Box className="staking-dialog-checkout-info-box">
              <Typography className="staking-dialog-checkout-title">
                Staking Tokens ({selectedItems.length})
              </Typography>
              <Typography className="staking-dialog-checkout-description">
                To stake your NFTs, you’ll need to complete a few steps first
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="checkout-card-vertical-divider"
            />
            <Box className="staking-dialog-checkout-info-box">
              <Box className="staking-dialog-checkout-card-row">
                <Typography className="staking-step-text">
                  APPROVE ARBGAL
                </Typography>
                {renderIfElse(
                  true,
                  <Box className="staking-done-step-box">
                    <img
                      alt="checked mark icon"
                      src={HawkIcons.CheckMarkIcon}
                      className="staking-done-step-icon"
                    />
                    <Typography className="staking-done-step-text">
                      Done
                    </Typography>
                  </Box>,
                  <Box className="staking-progress-box">
                    <img
                      src={Spinner}
                      alt="spinner"
                      className="staking-spinner-icon"
                    />
                    <Box className="staking-step-chip">
                      <Typography className="staking-step-chip-text">
                        1
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className="staking-dialog-checkout-card-row">
                <Typography className="staking-step-text">
                  STAKING COMPLETE
                </Typography>
                <Box className="staking-progress-box">
                  <img
                    src={Spinner}
                    alt="spinner"
                    className="staking-spinner-icon"
                  />
                  <Box className="staking-step-chip">
                    <Typography className="staking-step-chip-text">
                      2
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="checkout-card-vertical-divider"
            />
            <Box className="view-position-btn">
              <Typography className="view-position-btn-text">
                View position
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default HawkStakingDialog;
