// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Css import
import "./styles.css";

const CollectionPage = () => {
  return (
    <Box className="collection-page-container">
        <Typography>Collection Page</Typography>
    </Box>
  );
};

export default CollectionPage;
