// ** MUI import
import Box from "@mui/material/Box"
import CurrentUpComingSales from "../../components/HawkUpComingSale/CurrentUpComingSales";

// ** Components import
import HeroSection from "../../components/HeroSection";
import CTASection from "../../components/CTASection"
import StepsSection from "../../components/HawkStepsSection";

// ** Css import
import "./styles.css"
import HawkOverlapImages from "../../components/HawkOverlapImages/HawkOverlapImages";

const HomePage = () => {
    return (
        <Box className="home-page-container">
            <HeroSection />
            <CurrentUpComingSales/>
	        <HawkOverlapImages/>
            <StepsSection />
            <CTASection />
        </Box>
    )
}

export default HomePage
