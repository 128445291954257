// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import InoMintingStage from "./InoMintingStage";

// ** Types import
import { IFakeInoMetadata } from "../../pages/InoDetailPage/types";

// Css import
import "./styles.css";

type InoMintingProgressProps = {
  ino: IFakeInoMetadata;
};

const InoMintingProgress = ({ ino }: InoMintingProgressProps) => {
  return (
    <Box className="ino-minting-progress-container">
      {ino.stages.map((stage) => (
        <InoMintingStage
          stage={stage}
          metadata={ino}
          key={`ino-minting-stage-${stage.name}`}
        />
      ))}
    </Box>
  );
};

export default InoMintingProgress;
