// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import Info from "./Detail/Info";
import InoMintingNotify from "../InoMintingNotify";
import InoMintingProgress from "../InoMintingProgress";
import HawkInoImagesGallery from "../HawkInoImagesGallery";

// ** Types import
import { IFakeInoMetadata } from "../../pages/InoDetailPage/types";

// ** Css import
import "./styles.css";

type HawkInoIntroduceProps = {
  ino: IFakeInoMetadata;
};

const HawkInoIntroduce = ({ ino }: HawkInoIntroduceProps) => {
  const [readMore, setRedMore] = useState<boolean>(false);

  const toggleDescription = () => {
    setRedMore((prev) => !prev);
  };

  return (
    <Box className="hawk-ino-introduce-container">
      <Box className="hawk-ino-introduce-description-box">
        <Typography
          className={`hawk-ino-introduce-description ${
            !readMore && "hidden-text"
          }`}
        >
          {ino.description}
        </Typography>
        <Box
          component={"div"}
          className="read-more-btn"
          onClick={toggleDescription}
        >
          <Typography className="read-more-text">
            {readMore ? "Hidden" : "Read more"}
          </Typography>
        </Box>
      </Box>
      <Box className="hawk-ino-nft-title-box">
        <Typography className="hawk-ino-nft-title">NFT Title</Typography>
      </Box>
      <Box className="hawk-ino-campaign-container">
        <Box className="hawk-ino-image-swiper-container">
          <HawkInoImagesGallery ino={ino} />
          <Box className="hawk-ino-tags-container">
            {ino.tags.map((tag, index) => {
              return (
                <Box
                  className={
                    index === 0
                      ? "hawk-hight-light-tag-box"
                      : "hawk-ino-tag-box"
                  }
                >
                  <Typography className="hawk-ino-tag">{tag}</Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className="hawk-ino-minting-progress-container">
          <InoMintingNotify />
          <InoMintingProgress ino={ino} />
        </Box>
      </Box>
      <Info />
    </Box>
  );
};

export default HawkInoIntroduce;
