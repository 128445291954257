// ** React import
import { useMemo, useState } from "react";

import { format, isAfter } from "date-fns";

// ** MUI import
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";

// ** Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Components import
import { HawkWhiteProgress } from "../HawkProgress";
import HawkVerticalStepper from "../HawkVerticalStepper";
import InoStageCountdown from "./InoStageCountdown";

// ** Helpers import
import {
  renderIfElse,
  renderNumber,
  renderIf,
} from "../../helpers/render-helper";

// ** Types import
import {
  IFakeInoStage,
  IFakeInoMetadata,
} from "../../pages/InoDetailPage/types";

// ** Assets import
import { HawkIcons } from "../../assets";

type InoMintingStageProps = {
  stage: IFakeInoStage;
  metadata: IFakeInoMetadata;
  onEndStage?: () => void;
};

const InoMintingStage = ({
  stage,
  metadata,
  onEndStage,
}: InoMintingStageProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEndEvent, setIsEndEvent] = useState<boolean>(true);
  const [isStartEvent, setIsStartEvent] = useState<boolean>(
    isAfter(new Date(), new Date(stage.startDate))
  );

  const isPublicStage = useMemo(() => {
    return stage.type === "PUBLIC";
  }, [stage.type]);

  const isMedianScreen = useMediaQuery("(min-width:600px)");

  const toggleExpandView = () => {
    setIsExpanded((prev) => !prev);
  };

  const onCountdownToStartComplete = () => {
    console.log("Event start");
    setIsStartEvent(true);
  };

  const onCountdownToEndComplete = () => {
    console.log("Event end");
    setIsEndEvent(true);
    if (onEndStage) {
      onEndStage();
    }
  };

  return (
    <Box className="ino-minting-stage-container">
      {renderIf(
        isMedianScreen,
        <Box className="ino-minting-stage-left-content">
          <HawkVerticalStepper
            active={isStartEvent}
            isLatestStep={isPublicStage}
            ended={isPublicStage && isEndEvent}
          />
        </Box>
      )}
      
      <Box className="ino-minting-stage-right-content">
        {renderIfElse(
          isExpanded,
          <Box className="detail-minting-stage-box">
            <Box
              component="div"
              className="minting-stage-detail-header"
              onClick={toggleExpandView}
            >
              <Box className="minting-stage-detail-info-box">
                <LazyLoadImage
                  alt="ino image"
                  src={metadata.images[0]}
                  className="ino-avatar-image"
                  effect="blur"
                />
                <Box className="ino-name-container">
                  <Box className="ino-name-box">
                    <Typography className="ino-name">
                      {metadata.name}
                    </Typography>
                    <Box className="minting-status-tag">
                      <Typography className="minting-status-text">
                        Status
                      </Typography>
                    </Box>
                  </Box>
                  <Typography className="ino-description-text">
                    Ino Description
                  </Typography>
                </Box>
              </Box>
              <img src={HawkIcons.CaretDownIcon} className="expanded-up-icon" />
            </Box>
            <Box className="ino-mint-price-row">
              <Typography className="ino-detail-secondary-text">
                Mint Price:{" "}
              </Typography>
              <Box className="ino-mint-price-box">
                <img
                  alt="mint price token icon"
                  src={HawkIcons.CurrencyIcon}
                  className="ino-mint-price-token-icon"
                />
                <Typography className="ino-mint-price-text">
                  {metadata.price.toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Box className="ino-mint-progress-row">
              <Box className="ino-mint-progress-label-box">
                <Typography className="ino-detail-secondary-text">
                  {50}% minted
                </Typography>
                <Typography className="ino-detail-secondary-text">
                  {renderNumber(1500)}/{renderNumber(3000)}
                </Typography>
              </Box>
              <HawkWhiteProgress progress={50} />
            </Box>
            <InoStageCountdown
              startTitle="Start Sale in:"
              endTitle="End Sale in:"
              startDate={stage.startDate}
              endDate={stage.endDate}
              onStartEvent={onCountdownToStartComplete}
              onEndEvent={onCountdownToEndComplete}
            />
            <Box className="ino-stage-time-container">
              <Box className="ino-stage-time-row">
                <Typography className="ino-detail-secondary-text">
                  Total Raise
                </Typography>
                <Typography className="ino-detail-white-text">
                  {format(new Date(stage.startDate), "hh:mm, dd MMM yyy")}
                </Typography>
              </Box>
              <Box className="ino-stage-time-row">
                <Typography className="ino-detail-secondary-text">
                  Ends In
                </Typography>
                <Typography className="ino-detail-white-text">
                  {format(new Date(stage.endDate), "hh:mm, dd MMM yyy")}
                </Typography>
              </Box>
            </Box>
            <Box component="button" className="minting-ino-buy-now-btn">
              <Typography className="minting-ino-buy-now-text">
                Buy now
              </Typography>
            </Box>
          </Box>,
          <Box
            component="div"
            className="summary-minting-stage-box"
            onClick={toggleExpandView}
          >
            <Box className="summary-minting-title-box">
              <Typography className="minting-stage-summary-title">
                {stage.name}
              </Typography>
              <Box className="minting-status-tag">
                <Typography className="minting-status-text">Status</Typography>
              </Box>
            </Box>
            <img src={HawkIcons.CaretDownIcon} className="expanded-down-icon" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InoMintingStage;
