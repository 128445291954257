import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface YieldPerformer {
  rank: string;
  name: string;
  price: string;
}

const YieldTopPerformers = () => {
  const tempData = [
    { rank: "1", name: "NONA", price: "1" },
    { rank: "2", name: "ARGBAL", price: "0" },
    { rank: "3", name: "TOUCAN", price: "0" },
    { rank: "4", name: "ROBO", price: "0" },
  ];
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className="table-title-text">
          7D Yield Top Performers
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <IconButton
            aria-label="left"
            sx={{
              backgroundColor: "transparent",
              padding: 0,
              margin: 0,
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            aria-label="right"
            sx={{
              backgroundColor: "transparent",
              padding: 0,
              margin: 0,
            }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
      <TableContainer component={Paper} className="table-style">
        <Table>
          <TableHead>
            <TableRow className="background-table-header">
              <TableCell className="inactive-text-color">Rank</TableCell>
              <TableCell className="inactive-text-color">Pool</TableCell>
              <TableCell className="inactive-text-color">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tempData &&
              tempData.map((item: YieldPerformer) => (
                <TableRow className="background-table-row">
                  <TableCell className="hide-border-bottom table-body-value-text">
                    #{item.rank}
                  </TableCell>
                  <TableCell className="hide-border-bottom table-body-value-text">
                    {item.name}
                  </TableCell>
                  <TableCell className="hide-border-bottom table-body-value-text">
                    ${item.price}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default YieldTopPerformers;
