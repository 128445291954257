import Box from "@mui/material/Box";
import './index.css'
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import {Button} from "@mui/material";
import {LazyLoadImage} from "react-lazy-load-image-component";
import HawkHero from './assets/hawk-overlap.png'
import Card1 from './assets/game-card-1.png'
import Card2 from './assets/game-card-2.png'
import Card3 from './assets/game-card-3.png'
import Card4 from './assets/game-card-4.png'
import Card5 from './assets/game-card-5.png'
import Card6 from './assets/game-card-6.png'
const HawkOverlapImages = () => {
	return (
	<Box className='img-grid-overlap-container'>
		<Box className='container-overlap'>
			<Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center'}}>
				<Box className='overlap-icon-logo'/>
				<Typography className="overlap-text-main">
					Library of featured collections
				</Typography>
				<small className="overlap-text-sub">Discover a curated selection NFTs. Build your dream NFT collection and unlock a world of digital ownership.</small>
				<Button
					size="small"
					variant="outlined"
					sx={{
						width: 'fit-content',
						textTransform: 'none',
						border: '2px solid #C8FF00',
						marginTop: '1rem', // Add spacing between button and text
						borderRadius: '10px',
						color: '#C8FF00',
						'&:hover': {
							backgroundColor: '#C8FF00',
							color: 'black',
						},
						padding: '10px 20px 10px 20px',
						gap: '10px'
					}}
				>
					<Typography>Show more</Typography>
					<EastIcon/>
				</Button>
				<Box className='set-image-card-container'>
					<Box className="image-card card-1"></Box>
					<Box className="image-card card-2"></Box>
					<Box className="image-card card-3"></Box>
					<Box className="image-card card-4"></Box>
					<Box className="image-card card-5"></Box>
					<Box className="image-card card-6"></Box>
				</Box>
			</Box>
		</Box>
		<Box className='hero-bottom-left-img'/>
	</Box>
	)
}
export default HawkOverlapImages
