// ** React Import
import { useMemo, useState, Fragment } from "react";

import { useNavigate } from "react-router-dom";

// ** MUI Import
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";

// ** Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Component import
import TrimmedAddressComponent from "../TrimmedAddressComponent";
import { HawkBlueYellowProgress } from "../../components/HawkProgress";

import HawkStakingDialog from "../HawkStakingDialog";

// ** Types import
import { IFakeHawkPoolMetadata } from "../../pages/StakingPoolsPage/types";

// ** Assets import
import { HawkIcons, HawkImages } from "../../assets";

// ** Css Import
import "./styles.css";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

type HawkPoolTableRowProps = {
  pool: IFakeHawkPoolMetadata;
};

const HawkPoolTableRow = ({ pool }: HawkPoolTableRowProps) => {
  const [openStakingDialog, setOpenStakingDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOpenStakeDialog = () => {
    setOpenStakingDialog(true);
  };

  const handleCloseStakingDialog = () => {
    setOpenStakingDialog(false);
  };

  const badgeClass = useMemo(() => {
    switch (pool.liquidity) {
      case 1:
        return "pool-green-badge";
      case 2:
        return "pool-yellow-badge";
      case 3:
        return "pool-red-badge";
    }
  }, [pool]);

  return (
    <Fragment>
      <HawkStakingDialog
        open={openStakingDialog}
        pool={pool}
        handleClose={handleCloseStakingDialog}
      />
      <TableRow
        className="hawk-pool-table-row"
        onClick={() => navigate(`/staking/pools/1`)}
      >
        <TableCell>
          <Box className="table-body-cell-content">
            <img
              alt="pool badge image"
              src={HawkImages.PoolImageBadge}
              className="hawk-pool-badge-image"
            />
            <LazyLoadImage
              src={pool.image}
              className="hawk-pool-image"
              effect="blur"
            />
            <Box className="hawk-pool-name-box">
              <Typography className="hawk-pool-main-text">
                {pool.name.length > 10 ? (
                  <TrimmedAddressComponent address={pool.name} />
                ) : (
                  pool.name
                )}
              </Typography>
              <Typography className="hawk-pool-secondary-text">
                {pool.description}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box className="table-body-cell-content">
            <Box className="hawk-pool-inner-content">
              <img alt="" src={HawkIcons.SnowIcon} className="hawk-pool-info-icon" />
              <Typography className="hawk-pool-main-text">
                {pool.apr.toFixed(2)}%
              </Typography>
              <img alt="" src={HawkIcons.InfoIcon} className="hawk-pool-info-icon" />
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box className="table-body-cell-content">
            <Typography className="hawk-pool-main-text">
              {formatter.format(pool.tvl)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box className="table-body-cell-content">
            <Box className={badgeClass} />
          </Box>
        </TableCell>
        <TableCell>
          <Box className="table-body-cell-content">
            <HawkBlueYellowProgress
              progress={(pool.currentStakers / pool.maxStakers) * 100}
              width="100px"
            />
          </Box>
        </TableCell>
        <TableCell>
          <Box className="table-body-cell-content">
            <Box
              className="pool-table-stake-btn"
              component="button"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenStakeDialog();
              }}
            >
              <Typography className="pool-table-stake-btn-text">
                Stake
              </Typography>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

type HawkPoolsTableProps = {
  pools: IFakeHawkPoolMetadata[];
};

const HawkPoolsTable = ({ pools }: HawkPoolsTableProps) => {
  return (
    <TableContainer className="hawk-pools-table-container">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "240px" }}>
              <Typography className="hawk-pools-table-header-text">
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="hawk-pools-table-header-text">
                Combined ARP
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="hawk-pools-table-header-text">
                TVL
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="hawk-pools-table-header-text">
                Liquidity
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="hawk-pools-table-header-text">
                Stakers
              </Typography>
            </TableCell>
            <TableCell sx={{ width: "100px" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pools.map((pool, index) => (
            <HawkPoolTableRow
              key={`hawk-pool-table-row-${index}`}
              pool={pool}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HawkPoolsTable;
