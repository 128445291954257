import {Box, Typography} from "@mui/material";
import {LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component";
import ComingSoonBg from './assets/comingsoon.png'
import ComingSoonAva from './assets/comingsoon-square.png'

import { HawkIcons } from "../../assets";

const ComingSoonPackage = () =>{
	return (
		<Box className='ino-box-container coming-soon-ino-box-container'>
			<Box sx={{display: 'flex', justifyContent: 'center',position: 'relative'}}>
				<LazyLoadImage
					alt="Icon Package"
					src={ComingSoonBg}
					effect="blur"
					className='image-banner'
				/>
				<Box className='attribute-container'>
					<Typography className='small-btn-field'>AI</Typography>
					<Typography className='small-btn-field'>Layer-2</Typography>
				</Box>
			</Box>
			<Box className='name-io-container'>
				<LazyLoadImage
					alt="Coming Soon Package"
					src={ComingSoonAva}
					effect="blur"
					className='small-image'
				/>
				<Typography className='space-grotesk-font name-io-text tba-text'>TBA</Typography>
			</Box>
			<hr className='package-line'/>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="line up chart" src={HawkIcons.ChartLineIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Total Raise</Typography>
				</Box>
				<Typography className='text-package-description'>TBA</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="clock icon" src={HawkIcons.ClockCountdownIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Ends In</Typography>
				</Box>
				<Typography className='text-package-description'>TBA</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="tag icon" src={HawkIcons.TagIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Sales Type</Typography>
				</Box>
				<Typography className='text-package-description'>TBA</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="bar chart" src={HawkIcons.ChartBarIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Market Marker</Typography>
				</Box>
				<Typography className='text-package-description'>TBA</Typography>
			</Box>
			<hr className='package-line'/>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="rocket" src={HawkIcons.RocketIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Backed By</Typography>
				</Box>
				<Typography className='text-package-description'>N/A</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="crown simple icon" src={HawkIcons.CrownSimpleIcon} className="current-sale-title-icon"/>
					<Typography className='text-package-title'>Highlights</Typography>
				</Box>
				<Typography className='text-package-description'>N/A</Typography>
			</Box>
			<Box sx={{display: 'flex', justifyContent: 'center', mt: '30px', mb: '30px'}}>
					<Typography className='coming-soon-text'>
						Coming soon ...
					</Typography>
			</Box>
		</Box>
	)
}

export default trackWindowScroll(ComingSoonPackage)
