// ** React Import
import { useMemo } from "react";

import { useNavigate } from "react-router-dom";

// ** MUI Import
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";

import { formatDistanceToNow } from "date-fns";

// ** Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import TrimmedAddressComponent from "../TrimmedAddressComponent";

// ** Types import
import { IFakeStakingActivity } from "../../pages/StakingActivityPage/types";

// ** Assets import
import { HawkIcons } from "../../assets";

// ** Css import
import "./styles.css";

type StakingActivityRowProps = {
  activity: IFakeStakingActivity;
};

const HawkStakingActivityRow = ({ activity }: StakingActivityRowProps) => {
  const navigate = useNavigate();

  const actionBoxClass = useMemo(() => {
    switch (activity.action) {
      case "SELL":
        return "hawk-sell-activity-box";
      case "BUY":
        return "hawk-buy-activity-box";
      case "STAKE":
        return "hawk-stake-box";
      case "UNSTAKE":
        return "hawk-un-stake-box";
    }
  }, [activity]);

  return (
    <TableRow className="hawk-activities-table-row">
      <TableCell>
        <Box className="hawk-activities-table-body-cell-content">
          <LazyLoadImage
            src={activity.image}
            className="hawk-activity-image"
            effect="blur"
          />
          <Box className="hawk-activity-name-box">
            <Typography className="hawk-activity-main-text">
              {activity.name.length > 10 ? (
                <TrimmedAddressComponent address={activity.name} />
              ) : (
                activity.name
              )}
            </Typography>
            <Typography className="hawk-activity-secondary-text">
              {activity.description}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box className="hawk-activities-table-body-cell-content">
          <Box className={actionBoxClass}>
            <Typography className="hawk-activity-action-text">
              {activity.action}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box className="hawk-activities-table-body-cell-content">
          <Typography className="hawk-activities-table-main-text">
            {`${activity.fee} ${activity.feeTokenSymbol}`}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box className="hawk-activities-table-body-cell-content">
          <Box className="hawk-activity-info-box">
            <Typography className="hawk-activities-table-main-text">
              {formatDistanceToNow(new Date(activity.timestamp), {
                addSuffix: true,
              })}
            </Typography>
            <Box className="hawk-activity-description-box">
              <Typography className="hawk-activities-table-secondary-text">
                View TX
              </Typography>
              <img
                alt=""
                src={HawkIcons.ArrowSquareShare}
                className="view-activity-tx-icon"
              />
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box className="hawk-activities-table-body-cell-content">
          <Box
            component="button"
            className="view-pool-btn"
            onClick={() => navigate(`/staking/pools/${activity.poolId}`)}
          >
            <Typography className="view-pool-btn-text">View Pool</Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

type StakingActivityTableProps = {
  activities: IFakeStakingActivity[];
};

const HawkStakingActivityTable = ({
  activities,
}: StakingActivityTableProps) => {
  return (
    <TableContainer className="hawk-activities-table-container">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "240px", height: "40px" }}>
              <Typography className="hawk-activities-table-header-text">
                Asset
              </Typography>
            </TableCell>
            <TableCell sx={{ height: "40px" }}>
              <Typography className="hawk-activities-table-header-text">
                Action
              </Typography>
            </TableCell>
            <TableCell sx={{ height: "40px" }}>
              <Typography className="hawk-activities-table-header-text">
                Fee
              </Typography>
            </TableCell>
            <TableCell sx={{ height: "40px" }}>
              <Typography className="hawk-activities-table-header-text">
                Info
              </Typography>
            </TableCell>
            <TableCell sx={{ width: "110px", height: "40px" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity, index) => (
            <HawkStakingActivityRow
              key={`hawk-pool-table-row-${index}`}
              activity={activity}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HawkStakingActivityTable;
