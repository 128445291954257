import { Link } from "react-router-dom";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assets import
import { HawkIcons, HawkImages } from "../../assets";

// ** Css import
import "./styles.css";

interface IHawkFooterLink {
  url: string;
  label: string;
}

interface IHawkFooterSocialLink {
  url: string;
  icon: string;
}

const hawkLinks: IHawkFooterLink[] = [
  {
    label: "Homepage",
    url: "#",
  },
  {
    label: "Github",
    url: "#",
  },
  {
    label: "Cookbook",
    url: "#",
  },
  {
    label: "Get started",
    url: "#",
  },
];

const hawkSocialIcon: IHawkFooterSocialLink[] = [
  {
    icon: HawkIcons.XIcon,
    url: "#",
  },
  {
    icon: HawkIcons.DiscordIcon,
    url: "#",
  },
  {
    icon: HawkIcons.TelegramIcon,
    url: "#",
  },
];

const hawkDescriptionLinks: IHawkFooterLink[] = [
  {
    label: "Terms & Conditions",
    url: "#",
  },
  {
    label: "Privacy Policy",
    url: "#",
  },
];

const HawkFooter = () => {
  return (
    <Box className="hawk-footer-container">
      <Box className="hawk-footer-content-row">
        <Box className="hawk-footer-left-side-links">
          <img alt="footer logo" src={HawkImages.HawkFooterLogo} className="hawk-footer-logo" />
          {hawkLinks.map((link) => (
            <Link
              to={link.url}
              target="_blank"
              className="hawk-footer-link-label"
              key={`hawk-social-link-${link.label}`}
            >
              {link.label}
            </Link>
          ))}
        </Box>
        <Box className="hawk-footer-right-side-links">
          <Typography className="hawk-footer-secondary-text">
            Hey, let’s join our community!
          </Typography>
          {hawkSocialIcon.map((link, index) => (
            <Box
              component={Link}
              to={link.url}
              target="_blank"
              key={`hawk-social-link-${index}`}
              sx={{
                padding: 0
              }}
            >
              <img className="hawk-footer-social-icon" src={link.icon} />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className="hawk-footer-reserved-row">
        <Typography className="hawk-footer-reserved-text">
          © 2024 Hawk Terminal. All rights reserved.
        </Typography>
        <Box className="hawk-footer-description-links">
          {hawkDescriptionLinks.map((link) => (
            <Link
              to={link.url}
              target="_blank"
              key={`hawk-description-link-${link.label}`}
              className="hawk-footer-description-link"
            >
              {link.label}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HawkFooter;
