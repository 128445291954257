// ** Types import
import { IFakeHawkPoolMetadata, IFakePoolRankingData } from "./types";

// ** Assets Import
import Pool1Image from "./images/pool-img1.png";
import Pool2Image from "./images/pool-img2.png";
import Pool3Image from "./images/pool-img3.png";

const pool1: IFakeHawkPoolMetadata = {
  image: Pool1Image,
  name: "BRAINS",
  description: "Smol Brains",
  apr: 0,
  tvl: 2981.25,
  liquidity: 1,
  currentStakers: 0,
  maxStakers: 100,
};

const pool2: IFakeHawkPoolMetadata = {
  image: Pool2Image,
  name: "GHOST",
  description: "Full Moon Talisman",
  apr: 0,
  tvl: 2981.25,
  liquidity: 2,
  currentStakers: 70,
  maxStakers: 100,
};

const pool3: IFakeHawkPoolMetadata = {
  image: Pool3Image,
  name: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
  description: "Description",
  apr: 0,
  tvl: 2981.25,
  liquidity: 3,
  currentStakers: 90,
  maxStakers: 100,
};

export const FakeHawkPools: IFakeHawkPoolMetadata[] = [pool1, pool2, pool3];

export const FakeHawkPoolDetail = pool1;

export const FakePoolRankingData: IFakePoolRankingData[] = [
  {
    id: 0,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 1,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 2,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 3,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 4,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 5,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 6,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
  {
    id: 7,
    pool: "0x107D213c2955719a59140f1E9e90Be77480D6Cd5",
    value: 2.7,
  },
];

export const FakePoolChartData = [
  {
    label: "Jan",
    revenue: 64854,
    cost: 32652,
  },
  {
    label: "Feb",
    revenue: 54628,
    cost: 42393,
  },
  {
    label: "Mar",
    revenue: 117238,
    cost: 50262,
  },
  {
    label: "Apr",
    revenue: 82830,
    cost: 64731,
  },
  {
    label: "May",
    revenue: 91208,
    cost: 41893,
  },
  {
    label: "Jun",
    revenue: 103609,
    cost: 83809,
  },
  {
    label: "Jul",
    revenue: 90974,
    cost: 44772,
  },
  {
    label: "Aug",
    revenue: 82919,
    cost: 37590,
  },
  {
    label: "Sep",
    revenue: 62407,
    cost: 43349,
  },
  {
    label: "Oct",
    revenue: 82528,
    cost: 45324,
  },
  {
    label: "Nov",
    revenue: 56979,
    cost: 47978,
  },
  {
    label: "Dec",
    revenue: 87436,
    cost: 39175,
  },
];
