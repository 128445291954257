// ** React import
import { ReactNode } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import HawkStepCard from "./HawkStepCard";

// ** Assets import
import { HawkImages } from "../../assets";

// ** Css import
import "./styles.css";

export type IHawkAction = "CONNECT_WALLET";

export interface IHawkStep {
  name: string;
  actionLabel: string;
  action: IHawkAction;
  activeStep: boolean;
  description: string;
}

// ** FAKE DATA
const steps: IHawkStep[] = [
  {
    name: "Step 1",

    activeStep: true,
    description:
      "Register your account by providing the required information. Check your inbox for the verification link.",
    actionLabel: "Connect Wallet",
    action: "CONNECT_WALLET",
  },
  {
    name: "Step 2",
    activeStep: false,
    description:
      "Connect Web3 Wallet to Your Hawk Terminal Account to interact with smart contracts on the platform.",
    actionLabel: "Connect Wallet",
    action: "CONNECT_WALLET",
  },
  {
    name: "Step 3",

    activeStep: false,
    description:
      "To ensure fairness and transparency, Hawk Terminal will verify account information and security measures",
    actionLabel: "Connect Wallet",
    action: "CONNECT_WALLET",
  },
  {
    name: "Step 4",

    activeStep: false,
    actionLabel: "Connect Wallet",
    description:
      "Once your account is set up and verified, you can start exploring and interacting with the different features of Hawk Terminal.",
    action: "CONNECT_WALLET",
  },
];

const StepsSection = () => {
  return (
    <Box className="steps-section-container">
      <Box className="steps-section-headers">
        <img
          src={HawkImages.HawkCharacter5}
          alt="hawk character 5"
          className="steps-section-character-img"
        />
        <Box className="steps-section-titles">
          <Typography className="steps-section-title">
            Become investor with 4 steps
          </Typography>
          <Typography className="steps-section-description">
            Etiam consectetur cras molestie sit lectus purus integer morbi.
          </Typography>
        </Box>
      </Box>

      <Box className="steps-container">
        {steps.map((step) => (
          <HawkStepCard key={`hawk-step-steps-${step.name}`} step={step} />
        ))}
      </Box>
    </Box>
  );
};

export default StepsSection;
