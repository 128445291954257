// ** MUI import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// ** Css import
import "./styles.css";

const HawkEmptyItems = () => {
  return (
    <Box className="hawk-empty-item-card">
      <Typography className="hawk-no-items-text">
        No assets eligible for NFTX vaults...
      </Typography>
      <Box className="hawk-guide-list">
        <Box className="hawk-guild-card">
          <Typography className="hawk-guild-card-title">
            How do I get eligible assets?
          </Typography>
          <Box>
            <Typography className="hawk-guild-card-description-text">
              To deposit and start earning yield on the 100’s of existing NFTX
              vaults you can:
            </Typography>
            <ul>
              <li className="hawk-guild-card-description-text">
                Buy & deposit NFTs into a vault
              </li>
              <li className="hawk-guild-card-description-text">
                Own a Vaults Token
              </li>
              <li className="hawk-guild-card-description-text">
                Be a Liquidity Provider for a Vault
              </li>
            </ul>
          </Box>
          <Box className="hawk-guild-action-row">
            <Box className="hawk-guild-action-col">
              <Typography className="hawk-guild-btn-label">
                I want to buy NFTs
              </Typography>
              <Button variant="outlined" className="hawk-guild-btn">
                Browse marketplace
              </Button>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: "rgba(255, 255, 255, 0.1)",
              }}
            />
            <Box className="hawk-guild-action-col">
              <Typography className="hawk-guild-btn-label">
                I want to buy tokens
              </Typography>
              <Button variant="outlined" className="hawk-guild-btn">
                Create Vault
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="hawk-guild-card">
          <Typography className="hawk-guild-card-title">
            How do I get eligible assets?
          </Typography>
          <Typography className="hawk-guild-card-description-text">
            NFTX contracts are permission less - vaults are created by user!
            Though we do recommend a starting point of at least 10 items paired
            with ETH to ensure there is enough liquidity to get going.
          </Typography>
          <Box className="hawk-guild-action-row">
            <Box className="hawk-guild-action-col">
              <Typography className="hawk-guild-btn-label">
                I want to buy tokens
              </Typography>
              <Button variant="outlined" className="hawk-guild-btn">
                Create Vault
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HawkEmptyItems;
