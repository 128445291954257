import {Box, Tab, Typography} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import DetailAbout from "./About";
import {useState} from "react";
import InfoIcon from '@mui/icons-material/Info';
import BarChartIcon from '@mui/icons-material/BarChart';
import './index.css'
import Activity from "./Activity";
const Info = () => {
	const [tab, setTab] = useState("1")
	
	//@ts-ignore
	const handleChangeTab = (event: SyntheticEvent, tab: string) => {
		setTab(tab);
	};
	return (
		<Box>
			<TabContext value={tab} >
				<Box sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					gap: '20px'
				}}>
					<TabList
						TabIndicatorProps={{
							sx: {
								backgroundColor: 'transparent', // Indicator (line) color
							},
						}}
						orientation="vertical"
						onChange={handleChangeTab}
						aria-label="lab API tabs example"
						sx={{
							display: 'flex',
							justifyContent: 'space-between', // Distribute space between tabs,
							flexDirection: 'column',
							width: '300px'
						}}
						className='tablist tab-size-detail'
					>
						<Tab
							className="space-grotesk-font"
							label={
								<Box
									sx={{
										gap: '10px',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<InfoIcon sx={{fontSize: '15px'}}/>
									<Typography className="space-grotesk-font" sx={{fontSize: '15px'}}>
										About
									</Typography>
								</Box>
							}
							sx={{
								fontWeight: '900',
								color: '#FFFFFF80',
								textAlign: 'left', // Ensure text is centered
								'&.Mui-selected': {
									color: '#C8FF00',
									backgroundColor: 'rgba(200,255,0,0.1)',
									borderTopLeftRadius: '5px',
									borderTopRightRadius: '5px',
								},
								"&.MuiTab-root":{
									alignItems: "start",
									display: 'flex',
									justifyContent: 'center'
								}
							}}
							value="1"
						/>
						<Tab
							className="space-grotesk-font"
							label={
								<Box
									sx={{
										display: 'flex',         // Use flex layout
										flexDirection: 'row',    // Row direction for icon and text
										alignItems: 'center',    // Align items vertically center
										justifyContent: 'flex-start', // Align items to the left horizontally
										gap: '10px',             // Add spacing between icon and text
									}}
								>
									<BarChartIcon sx={{ fontSize: '15px' }} />
									<Typography className="space-grotesk-font" sx={{ fontSize: '15px', textAlign: 'left' }}>
										Activity
									</Typography>
								</Box>
							}
							sx={{
								fontWeight: '900',
								textAlign: 'left',
								color: '#FFFFFF80',
								'&.Mui-selected': {
									color: '#C8FF00',
									backgroundColor: 'rgba(200,255,0,0.1)',
									borderTopLeftRadius: '5px',
									borderTopRightRadius: '5px',
								},
								"&.MuiTab-root":{
									alignItems: "start",
									display: 'flex',
									justifyContent: 'center'
								}
							}}
							value="2"
						/>
					</TabList>
					<TabPanel value="1" className='tab-size-detail-content'>
						<DetailAbout/>
					</TabPanel>
					<TabPanel value="2" className='tab-size-detail-content'>
						<Activity/>
					</TabPanel>
				</Box>
			</TabContext>
		</Box>
	)
}

export default Info

