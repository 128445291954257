import { IFakeStakingActivity } from "./types";

// ** Assets Import
import Pool1Image from "./images/pool-img1.png";
import Pool2Image from "./images/pool-img2.png";
import Pool3Image from "./images/pool-img3.png";

const activity1: IFakeStakingActivity = {
  id: 1,
  poolId: 1,
  name: "BRAINS",
  image: Pool1Image,
  description: "Smol Brains",
  fee: 0.4,
  feeTokenSymbol: "TOUCAN",
  action: "BUY",
  timestamp: "2024-12-03T08:24:04.802Z",
};

const activity2: IFakeStakingActivity = {
  id: 2,
  poolId: 2,
  image: Pool2Image,
  name: "GHOST",
  description: "Full Moon Talisman",
  fee: 0.4,
  feeTokenSymbol: "TOUCAN",
  action: "SELL",
  timestamp: "2024-12-03T08:24:04.802Z",
};

const activity3: IFakeStakingActivity = {
  id: 3,
  poolId: 2,
  image: Pool2Image,
  name: "GHOST",
  description: "Full Moon Talisman",
  fee: 0.4,
  feeTokenSymbol: "TOUCAN",
  action: "STAKE",
  timestamp: "2024-12-03T08:24:04.802Z",
};

const activity4: IFakeStakingActivity = {
  id: 4,
  poolId: 2,
  image: Pool2Image,
  name: "GHOST",
  description: "Full Moon Talisman",
  fee: 0.4,
  feeTokenSymbol: "TOUCAN",
  action: "UNSTAKE",
  timestamp: "2024-12-03T08:24:04.802Z",
};

export const FakeStakingActivities = [
  activity1,
  activity2,
  activity3,
  activity4,
];
