import { useEffect, useRef } from "react";
import { createChart, ColorType, CrosshairMode } from "lightweight-charts";

type ChartComponentProps = {
  data: any[];
  textColor?: string;
  lineColor?: string;
  areaTopColor?: string;
  areaBottomColor?: string;
  backgroundColor?: string;
};

const PoolLineChart = ({
  data,
  textColor,
  lineColor,
  areaTopColor,
  areaBottomColor,
  backgroundColor,
}: ChartComponentProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current?.clientWidth });
    };
    const chart = createChart(chartContainerRef.current!, {
      layout: {
        attributionLogo: false,
        background: {
          type: ColorType.Solid,
          color: backgroundColor ?? "transparent",
        },
        textColor: textColor ?? "white",
      },
      grid: {
        vertLines: {
            color: 'rgba(29, 31, 20, 1)'
        },
        horzLines: {
            color: 'rgba(29, 31, 20, 1)'
        }
      },
      crosshair: {
        mode: CrosshairMode.Normal
      },
      width: chartContainerRef.current?.clientWidth,
      height: 400,
    });

    chart.timeScale().fitContent();

    const lineSeries = chart.addLineSeries({ color: 'rgba(200, 255, 0, 1)' });
    lineSeries.setData(data);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, [data]);

  return <div ref={chartContainerRef} style={{width: '100%'}} />;
};

export default PoolLineChart;
