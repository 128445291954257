import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";

// ** Components import
import TrimmedAddressComponent from "../../../components/TrimmedAddressComponent";

// ** Types import
import { IFakePoolRankingData } from "../types";

type PoolRankingTableProps = {
  data: IFakePoolRankingData[];
};

const PoolRankingTable = ({ data }: PoolRankingTableProps) => {
  return (
    <TableContainer className="pool-ranking-table-container">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography className="pool-ranking-table-header-text">
                Pool
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className="pool-ranking-table-header-text">
                Share
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((poolData) => (
            <TableRow
              key={`ranking-data-${poolData.id}`}
              className="pool-rank-table-row"
            >
              <TableCell>
                <Typography className="pool-ranking-table-value-text">
                  <TrimmedAddressComponent address={poolData.pool} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className="pool-ranking-table-value-text">
                  {poolData.value.toFixed(2)}%
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PoolRankingTable