import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import AzaLogo from "./images/aza.png";
import AleoLogo from "./images/aleo.png";
import MinaLogo from "./images/mina.png";
import Web3Logo from "./images/web3.png";
import SmapeLogo from "./images/smape.png";
import PrestoLogo from "./images/presto.png";
import MaxxLogo from "./images/maxx-logo.png";
import EthereumLogo from "./images/ethereum.png";
import BiconomyLogo from "./images/biconomy.png";

import "./styles.css";

interface ILogoInfo {
  src: string;
  className: string;
}

const EthereumLogoInfo: ILogoInfo = {
  src: EthereumLogo,
  className: "ethereum-logo",
};

const MinaLogoInfo: ILogoInfo = {
  src: MinaLogo,
  className: "mina-logo",
};

const Web3LogoInfo: ILogoInfo = {
  src: Web3Logo,
  className: "web3-logo",
};

const AleoLogoInfo: ILogoInfo = {
  src: AleoLogo,
  className: "aleo-logo",
};

const PrestoLogoInfo: ILogoInfo = {
  src: PrestoLogo,
  className: "presto-logo",
};

const SmapeLogoInfo: ILogoInfo = {
  src: SmapeLogo,
  className: "smape-logo",
};

const BiconomyLogoInfo: ILogoInfo = {
  src: BiconomyLogo,
  className: "biconomy-logo",
};

const AzaLogoInfo: ILogoInfo = {
  src: AzaLogo,
  className: "aza-logo",
};

const MaxxLogoInfo: ILogoInfo = {
  src: MaxxLogo,
  className: "maxx-logo",
};

const BackedLogoList = [
  EthereumLogoInfo,
  MinaLogoInfo,
  Web3LogoInfo,
  AleoLogoInfo,
  EthereumLogoInfo,
  MinaLogoInfo,
  PrestoLogoInfo,
  SmapeLogoInfo,
  BiconomyLogoInfo,
  AzaLogoInfo,
  MaxxLogoInfo,
  Web3LogoInfo,
  AleoLogoInfo,
];

const BackedLogoSlider = () => {
  return (
    <Box className="backed-logo-slider-container">
      <Box className="backed-logo-slider">
        {BackedLogoList.map((logo, index) => (
          <img
          src={logo.src}
          alt="braked logo"
          key={`backed-log-${index}`}
            className={logo.className}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BackedLogoSlider;
