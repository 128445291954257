// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assets Import
import { HawkImages, HawkIcons } from "../../assets";

// ** Css import
import "./styles.css";

const CTASection = () => {
  return (
    <Box className="cta-section-container">
      <Box className="cta-section-content">
        <img
          src={HawkImages.HawkCharacter2}
          className="cta-section-left-character"
        />
        <img
          src={HawkImages.HawkCharacter4}
          className="cta-section-right-character"
        />
        <Box className="cta-section-title-box">
          <Typography className="cta-section-content-title">
            Empowering Web3 Community
          </Typography>
          <Typography className="cta-section-content-description">
            Hawk Terminal will accompany with Games & Developers to lay solid
            foundations for Web3 Gaming revolution.
          </Typography>
        </Box>
        <Box className="cta-section-action-box">
          <Box component="button" className="cta-section-learn-more-btn">
            <Typography>Learn more</Typography>
          </Box>
          <Box component="button" className="cta-section-launch-btn">
            <Typography>Launch App</Typography>
            <img
              alt="arrow right icon"
              src={HawkIcons.ArrowRightIcon}
              className="launch-app-btn-icon"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CTASection;
