// ** React import
import { useState, Fragment } from "react";

// react-router imports
import { NavLink, useLocation } from "react-router-dom";

// ** MUI import
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// ** Css import
import "./styles.css";
import { renderIfElse } from "../../helpers/render-helper";

import { HawkImages, HawkIcons } from "../../assets";

export interface IHawkViewItem {
  label: string;
  link: string;
  badge?: string;
  enable: boolean;
}

const ViewList: IHawkViewItem[] = [
  {
    label: "IGO",
    link: "/igo",
    enable: true,
  },
  {
    label: "Collection",
    link: "/collection",
    enable: false,
    badge: "Coming soon",
  },
  {
    label: "Staking",
    link: "/staking",
    enable: true,
    badge: "Coming soon",
  },
];

type HawkAppBarItemProps = {
  isActive: boolean;
  item: IHawkViewItem;
};

const HawkAppBarLinkItem = ({ isActive, item }: HawkAppBarItemProps) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <Fragment>
      {renderIfElse(
        item.enable,
        <Box
          component={NavLink}
          to={item.link}
          className={
            isActive ? "hawk-app-bar-active-item" : "hawk-app-bar-item"
          }
        >
          <Typography className="hawk-app-bar-item-text">
            {item.label}
          </Typography>
        </Box>,
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<>{item.badge && <Typography>{item.badge}</Typography>}</>}
          >
            <Box
              component="div"
              onClick={handleTooltipOpen}
              className="hawk-app-bar-item"
            >
              <Typography>{item.label}</Typography>
            </Box>
          </Tooltip>
        </ClickAwayListener>
      )}
    </Fragment>
  );
};

const HawkAppBar = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const location = useLocation();

  return (
    <AppBar elevation={0} className="hawk-app-bar-container">
      <Box className="hawk-app-bar-content">
        <img src={HawkImages.HawkAppBarLogo} className="hawk-app-bar-logo" />
        <Box className="hawk-app-bar-right-side-content">
          <Box className="hawk-app-bar-item-container">
            {ViewList.map((view) => {
              const isActive =
                location.pathname === view.link ||
                location.pathname.includes(view.link) ||
                (location.pathname === "/" && view.label === "IGO") ||
                (location.pathname.includes("/ino") && view.label === "IGO");
              return (
                <HawkAppBarLinkItem
                  key={`hawk-link-${view.label}`}
                  item={view}
                  isActive={isActive}
                />
              );
            })}
            <Box className="divider-item" />
          </Box>
          <Box className="hawk-app-bar-btn-container">
            <Box className="hawk-app-bar-switch-network-btn secondary-outline-btn">
              <Typography className="hawk-app-bar-connect-btn-text">
                Select network
              </Typography>
              <img
                alt="caret-down-icon"
                src={HawkIcons.CaretDownIcon}
                className="hawk-app-bar-action-icon"
              />
            </Box>
            <Box className="hawk-app-bar-connect-network-btn primary-outline-btn">
              <Typography className="hawk-app-bar-connect-btn-text">
                Connect wallet
              </Typography>
              <img
                alt="caret-down-icon"
                src={HawkIcons.ArrowRightGreenIcon}
                className="hawk-app-bar-action-icon"
              />
            </Box>
          </Box>
          <Box
            component="div"
            className="hawk-app-bar-menu-btn"
            onClick={toggleDrawer(true)}
          >
            <img
              src={HawkIcons.MenuIcon}
              alt="menu icon"
              className="hawk-app-bar-menu-icon"
            />
          </Box>
        </Box>
        <Box className="hawk-mobile-app-bar-content">
          <Drawer
            open={open}
            anchor="top"
            aria-label="App bar menu"
            onClick={toggleDrawer(false)}
          >
            <Box className="hawk-app-bar-drawer-container">
              <Box className="hawk-app-bar-drawer-app-bar-items">
                {ViewList.map((view) => {
                  const isActive =
                    location.pathname === view.link ||
                    location.pathname.includes(view.link) ||
                    (location.pathname === "/" && view.label === "IGO");
                  return (
                    <HawkAppBarLinkItem
                      key={`hawk-link-${view.label}`}
                      item={view}
                      isActive={isActive}
                    />
                  );
                })}
              </Box>
              <Box className="hawk-app-bar-switch-network-btn secondary-outline-btn">
                <Typography className="hawk-app-bar-connect-btn-text">
                  Select network
                </Typography>
                <img
                  alt="caret-down-icon"
                  src={HawkIcons.CaretDownIcon}
                  className="hawk-app-bar-action-icon"
                />
              </Box>
              <Box className="hawk-app-bar-connect-network-btn primary-outline-btn">
                <Typography className="hawk-app-bar-connect-btn-text">
                  Connect wallet
                </Typography>
                <img
                  alt="caret-down-icon"
                  src={HawkIcons.ArrowRightGreenIcon}
                  className="hawk-app-bar-action-icon"
                />
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </AppBar>
  );
};

export default HawkAppBar;
