import { ReactNode } from "react";

/**
 * @example
 * <div>
 *  {renderIf(isLoggedIn, <p>Welcome, user!</p>)}
 *  {renderIf(!isLoggedIn, <p>Please log in.</p>)}
 * </div>
 * @param condition
 * @param children
 * @returns
 */
export const renderIf = (
  condition: boolean,
  children: ReactNode
): ReactNode => {
  return condition ? children : null;
};

/**
 * @example
 * <div>
 *  {renderIfElse(isLoggedIn, <p>Welcome, user!</p>, <p>Please log in.</p>)}
 * </div>
 * @param condition
 * @param ComponentIfTrue
 * @param ComponentIfFalse
 * @returns
 */
export const renderIfElse = (
  condition: boolean,
  ComponentIfTrue: ReactNode,
  ComponentIfFalse: ReactNode
): ReactNode => {
  return condition ? ComponentIfTrue : ComponentIfFalse;
};

/**
 * @example
 * <div>
 * {renderSwitch([
 *  { condition: status === 'loading', children: <p>Loading...</p> },
 *  { condition: status === 'success', children: <p>Data loaded successfully!</p> },
 *  { condition: status === 'error', children: <p>Failed to load data.</p> },
 * ])}
 * </div>
 *
 * @param cases
 * @param defaultRender
 * @returns
 */
export const renderSwitch = (
  cases: { condition: boolean; children: ReactNode }[],
  defaultRender: ReactNode = null
): ReactNode => {
  for (const caseItem of cases) {
    if (caseItem.condition) {
      return caseItem.children;
    }
  }
  return defaultRender;
};

/**
 * @example
 * <div>
 *  {renderWithFallback(data && <p>Data is available: {data}</p>, <p>Data is not available</p>)}
 * </div>
 * @param primary
 * @param fallback
 * @returns
 */
export const renderWithFallback = (
  primary: ReactNode,
  fallback: ReactNode
): ReactNode => {
  return primary || fallback;
};

/**
 * Sleep for a specified number of milliseconds.
 * @param ms - The number of milliseconds to sleep.
 * @returns A promise that resolves after the specified delay.
 */
export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// export const renderArray = (elements: ReactNode[]): ReactNode => {
//   return (
//     <>
//       {elements.map((element, index) => (
//         <React.Fragment key={index}>{element}</React.Fragment>
//       ))}
//     </>
//   );
// };

export const renderNumber = (number: any, locale?: "en-US") => {
  if (!isNaN(number)) return new Intl.NumberFormat(locale).format(number);
  return null;
};

export const renderCurrency = (number: any, locale?: "en-US") => {
  if (isNaN(number))
    return new Intl.NumberFormat(locale, {
      style: "currency",
    }).format(number);
  
  return null;
};

export function onlyUnique<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index;
}