interface TrimmedAddressComponentProps {
  address: string;
  startChars?: number;
  endChars?: number;
}

export default function TrimmedAddressComponent({
  address,
  startChars,
  endChars,
}: TrimmedAddressComponentProps) {
  const trimmedAddress =
    address.substring(0, startChars ?? 6) +
    "..." +
    address.substring(address.length - (endChars ?? 6));

  return <>{trimmedAddress}</>;
}
