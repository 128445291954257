// ** React import
import { Fragment } from "react";

// ** MUI import
import Box from "@mui/material/Box";

// ** Helpers import
import { renderIf, renderIfElse } from "../../helpers/render-helper";

// ** Assets import
import CircleIcon from "./images/circle.svg";
import CheckCircleIcon from "./images/check-circle.svg";

// ** Css import
import "./styles.css";

type HawkVerticalStepperProps = {
  active: boolean;
  ended: boolean;
  isLatestStep: boolean;
};

const HawkVerticalStepper = ({
  active,
  ended,
  isLatestStep,
}: HawkVerticalStepperProps) => {
  return (
    <Box className="hawk-stepper-container">
      {renderIfElse(
        active,
        <Fragment>
          <img
            alt="checked icon"
            src={CheckCircleIcon}
            className="check-circle-icon"
          />
          {renderIf(!isLatestStep, <Box className="active-line-box" />)}
        </Fragment>,
        <Fragment>
          <img alt="circle icon" src={CircleIcon} className="circle-icon" />
          {renderIf(!isLatestStep, <Box className="line-box" />)}
        </Fragment>
      )}
    </Box>
  );
};

export default HawkVerticalStepper;
