import InoImage1 from "./images/ino-img-1.png";
import InoImage2 from "./images/ino-img-2.png";

import InoAvatar from "./images/ino-avatar.png";
import InoBackgroundImage from "./images/ino-header-bg.png";
import { IFakeInoMetadata, IFakeInoStage } from "./types";

export const AllowlistStage: IFakeInoStage = {
  name: "Allowlist",
  type: "ALLOWLIST",
  startDate: "2024-11-27T07:51:03.750Z",
  endDate: "2024-12-12T07:51:03.750Z",
};

export const PublicStage: IFakeInoStage = {
  name: "Public",
  type: "PUBLIC",
  startDate: "2024-12-12T07:51:03.750Z",
  endDate: "2025-02-26T07:51:03.750Z",
};

export const FakeIno: IFakeInoMetadata = {
  inoId: "1",
  avatar: InoAvatar,
  name: "CyberKongz Genkai",
  images: [InoImage1, InoImage2],
  isVerified: true,
  backgroundImage: InoBackgroundImage,
  price: 20,
  supply: 3000,
  minted: 3000,
  tags: ["AI", "Base", "SportFi"],
  creator: "CyberKongz",
  description:
    "Pharetra ullamcorper pharetra aliquam gravida massa. Vitae consectetur id vitae nulla felis sodales condimentum lorem sem. Mattis amet turpis gravida vulputate ut. Augue urna vulputate amet pharetra nulla. Dignissim ultricies dictumst mattis malesuada quam viverra at quam. Blandit lacus amet sapien ornare nec aenean. Erat vitae vel a nulla eget non laoreet volutpat eget. Eget proin sagittis elementum tincidunt purus. Nunc porttitor feugiat cursus posuere ac vitae. Mi feugiat eu dolor consequat sed risus pellentesque.",

  stages: [AllowlistStage, PublicStage],

  x: "http://localhost:3000/ino/1",
  web: "http://localhost:3000/ino/1",
  discord: "http://localhost:3000/ino/1",
  telegram: "http://localhost:3000/ino/1",
};
