// ** React import
import { Fragment, useState } from "react";

import { Outlet } from "react-router-dom";

// react-router imports
import { NavLink, useLocation } from "react-router-dom";

// ** MUI import
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// ** Components import
import TrimmedAddressComponent from "../components/TrimmedAddressComponent";

// Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Helpers import
import { renderIfElse } from "../helpers/render-helper";

// ** Assets import
import FakeAvatar from "./images/avatar.png";

// ** Assets import
import { HawkIcons } from "../assets";

// ** Css import
import "./styles.css";

const FAKE_ADDRESS = "0x107D213c2955719a59140f1E9e90Be77480D6Cd5";

export interface IStakingViewItem {
  label: string;
  link: string;
  enable: boolean;
}

const ViewList: IStakingViewItem[] = [
  {
    label: "Dashboard",
    link: "/dashboard",
    enable: true,
  },
  {
    label: "My Items",
    link: "/my-items",
    enable: true,
  },
  {
    label: "Pools",
    link: "/pools",
    enable: true,
  },
  {
    label: "Activity",
    link: "/activity",
    enable: true,
  },
];

type StakingViewItemProps = {
  isActive: boolean;
  item: IStakingViewItem;
};

const StakingViewItem = ({ isActive, item }: StakingViewItemProps) => {
  return (
    <Box
      component={NavLink}
      to={`/staking${item.link}`}
      className={isActive ? "active-stacking-tab" : "staking-tab"}
    >
      <Typography className="stacking-tab-label">{item.label}</Typography>
    </Box>
  );
};

const StakingLayout = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const isLargeScreen = useMediaQuery("(min-width:900px)");

  const handleOpenSideBar = () => {
    setShowDrawer(true);
  };

  const handleCloseSideBar = () => {
    setShowDrawer(false);
  };

  return (
    <Box className="staking-page-container">
      {/* left sidebar */}
      {renderIfElse(
        isLargeScreen,
        <Box className="staking-page-left-sidebar">
          <Box className="left-side-bar-content">
            <Box className="account-info-container">
              <Box className="account-description">
                <Typography className="account-address">
                  <TrimmedAddressComponent
                    address={FAKE_ADDRESS}
                    startChars={4}
                    endChars={4}
                  />
                </Typography>
                <Typography className="account-balance">${0.0074}</Typography>
              </Box>
              <LazyLoadImage
                src={FakeAvatar}
                alt="account avatar"
                className="account-avatar"
              />
            </Box>
            <Box className="staking-sidebar-divider" />
          </Box>
          {ViewList.map((item) => {
            const isActive =
              pathname.includes(item.link) ||
              (item.label === "Dashboard" && pathname === "/staking");
            return (
              <StakingViewItem
                key={`staking-view-item-${item.label}`}
                item={item}
                isActive={isActive}
              />
            );
          })}
        </Box>,
        <Fragment>
          <IconButton
            sx={{ position: "absolute", top: "0", left: "0", zIndex: '10' }}
            onClick={handleOpenSideBar}
          >
            <img
              src={HawkIcons.ArrowRightGreenIcon}
              alt="arrow right icon"
              className="staking-menu-icon"
            />
          </IconButton>
          <Drawer
            anchor="left"
            open={showDrawer}
            onClose={handleCloseSideBar}
            PaperProps={{
              style: {
                marginTop: "var(--hawk-app-bar-height)",
                backgroundColor: "transparent",
                backgroundImage: "none",
                boxShadow: "none",
              },
            }}
          >
            <Box className="staking-page-left-sidebar">
              <Box className="left-side-bar-content">
                <Box className="account-info-container">
                  <Box className="account-description">
                    <Typography className="account-address">
                      <TrimmedAddressComponent
                        address={FAKE_ADDRESS}
                        startChars={4}
                        endChars={4}
                      />
                    </Typography>
                    <Typography className="account-balance">
                      ${0.0074}
                    </Typography>
                  </Box>
                  <LazyLoadImage
                    src={FakeAvatar}
                    alt="account avatar"
                    className="account-avatar"
                  />
                </Box>
                <Box className="staking-sidebar-divider" />
              </Box>
              {ViewList.map((item) => {
                const isActive =
                  pathname.includes(item.link) ||
                  (item.label === "Dashboard" && pathname === "/staking");
                return (
                  <StakingViewItem
                    key={`staking-view-item-${item.label}`}
                    item={item}
                    isActive={isActive}
                  />
                );
              })}
            </Box>
          </Drawer>
        </Fragment>
      )}
      <Outlet />
    </Box>
  );
};

export default StakingLayout;
