import { useNavigate } from "react-router-dom";

import { Box, IconButton, Typography, Button, Divider } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import Countdown, { CountdownRenderProps } from "react-countdown";

import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { HawkYellowProgress } from "../../../components/HawkProgress";

import GenkaiAva from "../assets/genkai_ava.png";

import "../index.css";
import "../detail.css";

const StakingDashboardDetail = () => {
  const navigate = useNavigate();
  const tempData = {
    inventoryPosition: {
      staked: "$0",
      apr: "0.07%",
      "pool share": "0.04%",
      "lifetime reward": "0.00 ARBGAL",
      locked: "13 minutes remaining",
    },
    liquidityPosition: {
      staked: "$0",
      apr: "0.07%",
      "pool share": "0.04%",
      "lifetime reward": "0.00 ARBGAL",
    },
  };

  const countdownTime = ({
    total,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      return "";
    } else {
      return (
        <Typography className="remaining-time-color">
          {days}d {hours}h {minutes}m {seconds}s remaining
        </Typography>
      );
    }
  };
  return (
    <Box className="detail-container">
      <Box className="staking-dashboard-detail-nav-row">
        <IconButton
          aria-label="back"
          sx={{
            backgroundColor: "transparent",
            borderRadius: "10px",
            paddingLeft: "20px",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon sx={{ fontSize: "16px" }} />
          <Typography
            sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "500" }}
          >
            Back
          </Typography>
        </IconButton>
      </Box>
      <Box className="staking-dashboard-content">
        <Box className="detail-ava-attribute-container">
          <Box className="detail-avatar-info-container">
            <LazyLoadImage src={GenkaiAva} effect="blur" className="avatar" />
            <Box className="side-avatar-info">
              <Typography className="dashboard-detail-page-title-text">
                ARBGAL
              </Typography>
              <Typography className="dashboard-detail-page-description-text">
                Arbigans Galxe
              </Typography>
              <Box className="avatar-action-btn-group">
                <Button className="sub-button">
                  Buy on Sushi <ArrowOutwardIcon sx={{ fontSize: "14px" }} />
                </Button>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    borderColor: "rgba(255, 255, 255, 0.1)",
                  }}
                />
                <Button className="sub-button">
                  Pool Info <ArrowOutwardIcon sx={{ fontSize: "14px" }} />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="staking-dashboard-attribute-group">
            <Box className="attribute-box">
              <Typography className="dashboard-detail-yellow-text">
                $0
              </Typography>
              <Typography className="title-attribute-text">
                INVENTORY VALUE
              </Typography>
            </Box>
            <Box className="attribute-box">
              <Typography className="dashboard-detail-blue-text">
                $23
              </Typography>
              <Typography className="title-attribute-text">
                LIQUIDITY VALUE
              </Typography>
            </Box>
            <Box className="attribute-box">
              <Typography>
                <b>$0</b>
              </Typography>
              <Typography className="title-attribute-text">
                TOTAL VALUE
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="staking-dashboard-detail-content">
          <Box className="position-line-container">
            <Typography className="title-attribute-text">
              POSITION SPLIT
            </Typography>
            <HawkYellowProgress progress={70} />
          </Box>
          <Box className="positions-container">
            <Box className="box-num-container">
              <Typography className="dashboard-detail-yellow-text">
                Inventory Position
              </Typography>
              <Box className="detail-attr-container">
                {Object.entries(tempData?.inventoryPosition).map(
                  ([key, value]) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography className="title-attribute-text">
                        {key.toUpperCase()}
                      </Typography>
                      {key === "locked" ? (
                        <Countdown
                          date="2025-02-01T01:02:03"
                          renderer={countdownTime}
                        />
                      ) : (
                        <Typography className="value-attribute-text">
                          {value}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
              </Box>
              <Divider
                orientation="horizontal"
                variant="middle"
                sx={{
                  borderColor: "rgba(255, 255, 255, 0.1)",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  mt: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  className="check-my-item-button full-width-btn"
                >
                  Unstake
                </Button>
                <Box
                  component="button"
                  className="manage-button full-width-btn"
                >
                  <Typography className="manage-button-text">Stake</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="box-num-container">
              <Typography className="dashboard-detail-blue-text">
                Liquidity Position
              </Typography>
              <Box className="detail-attr-container">
                {Object.entries(tempData?.liquidityPosition).map(
                  ([key, value]) => (
                    <Box
                      key={key}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography className="title-attribute-text">
                        {key.toUpperCase()}
                      </Typography>
                      {key === "locked" ? (
                        <Countdown
                          date="2025-02-01T01:02:03"
                          renderer={countdownTime}
                        />
                      ) : (
                        <Typography className="value-attribute-text">
                          {value}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
              </Box>
              <Divider
                orientation="horizontal"
                variant="middle"
                sx={{
                  borderColor: "rgba(255, 255, 255, 0.1)",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  mt: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  className="check-my-item-button full-width-btn"
                >
                  Unstake
                </Button>
                <Box
                  component="button"
                  className="manage-button full-width-btn"
                >
                  <Typography className="manage-button-text">Stake</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default StakingDashboardDetail;
