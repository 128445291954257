// ** React import
import { useState, useCallback } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { HawkYellowProgress } from "../HawkProgress";

import HawkWalletAssets from "../HawkWalletAssets";

// ** Helpers import
import { onlyUnique } from "../../helpers/render-helper";

// ** Assets Import
import { HawkIcons } from "../../assets";

// ** Css import
import "./styles.css";

// ** Fake Assets
import FakeAvatar from "./images/avatar-image.png";

type HawkCreateVaultDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const HawkCreateVaultDialog = ({
  open,
  handleClose,
}: HawkCreateVaultDialogProps) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([2]);

  const handleToggleSelectItem = useCallback(
    (id: number) => {
      if (selectedItems.includes(id)) {
        const _newItems = selectedItems.filter((item) => item !== id);
        setSelectedItems(_newItems);
      } else {
        setSelectedItems((prev) => {
          return [...prev, id];
        });
      }
    },
    [selectedItems]
  );

  const handleSelectItems = (ids: number[]) => {
    setSelectedItems((prev) => {
      const _newItems = [...prev, ...ids];

      return _newItems.filter(onlyUnique);
    });
  };

  const handleRemoveAllItem = () => {
    setSelectedItems([]);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{
        style: {
          backgroundColor: "rgba(14, 15, 10, 1)",
          backgroundImage: "none",
          boxShadow: "none",
        },
      }}
    >
      <Box className="hawk-create-vault-dialog-container">
        <img
          onClick={handleClose}
          src={HawkIcons.CloseIcon}
          alt="close icon"
          className="create-vault-dialog-close-icon"
        />
        <Box className="create-vault-pool-title-header">
          <Box className="create-vault-pool-header-img-box">
            <LazyLoadImage
              src={FakeAvatar}
              alt="pool image"
              effect="blur"
              className="create-vault-pool-header-img"
            />
            <Box className="create-vault-pool-detail-image-overlay" />
          </Box>
          <Box className="create-vault-pool-title-info-box">
            <Typography className="create-vault-pool-name">
              {"ARBGAL"}
            </Typography>
            <Typography className="create-vault-pool-description">
              {"Arbigans Galxe"}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="horizontal"
          variant="middle"
          className="create-vault-dialog-horizontal-divider"
        />
        <Box className="create-vault-dialog-content">
          <Box sx={{ flex: 1 }}>
            <HawkWalletAssets
              selectedItems={selectedItems}
              handleSelectItems={handleSelectItems}
              handleToggleSelectItem={handleToggleSelectItem}
              handleRemoveAllItem={handleRemoveAllItem}
            />
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="create-vault-dialog-vertical-divider"
          />
          <Box className="create-vault-dialog-checkout-card">
            <Box className="create-vault-dialog-checkout-card-row">
              <Typography className="create-vault-dialog-checkout-title">
                Selected Tokens ({selectedItems.length})
              </Typography>
              <Box
                component="button"
                className="create-vault-dialog-clear-btn"
                onClick={handleRemoveAllItem}
              >
                <Typography className="create-vault-dialog-clear-btn-text">
                  Clear
                </Typography>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="checkout-card-vertical-divider"
            />
            <Box className="create-vault-dialog-apr-row">
              <Typography className="create-vault-yellow-text">
                {0.07}%
              </Typography>
              <Typography className="create-vault-label">APR</Typography>
            </Box>
            <Box className="create-vault-dialog-checkout-info-box">
              <Box className="create-vault-dialog-checkout-card-row">
                <Typography className="create-vault-title-text">
                  APR Impact
                </Typography>
                <Typography className="create-vault-value-text">
                  {0.07}% → {0.07}%
                </Typography>
              </Box>
              <Box className="create-vault-dialog-checkout-card-row">
                <Typography className="create-vault-title-text">
                  Pool Share
                </Typography>
                <Typography className="create-vault-value-text">
                  {0}% → {0.05}%
                </Typography>
              </Box>
              <Box className="create-vault-dialog-checkout-card-row">
                <Typography className="create-vault-title-text">
                  Lock Time
                </Typography>
                <Typography className="create-vault-yellow-text">
                  13 minutes
                </Typography>
              </Box>
              <Typography className="create-vault-secondary-label">
                Remaining position split
              </Typography>
              <HawkYellowProgress progress={70} />
              <Box className="create-vault-dialog-checkout-card-row">
                <Box>
                  <Typography className="create-vault-label">{100}%</Typography>
                  <Typography className="create-vault-secondary-label">
                    Inventory
                  </Typography>
                </Box>
                <Box>
                  <Typography className="create-vault-label">{0}%</Typography>
                  <Typography className="create-vault-secondary-label">
                    Liquidity
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="checkout-card-vertical-divider"
            />
            <Box className="create-vault-dialog-stake-btn">
              <Typography className="create-vault-dialog-stake-btn-text">
                Stake
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default HawkCreateVaultDialog;
