// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// ** Assets import
import { HawkIcons, HawkImages } from "../../assets";

import BackedLogoSlider from "../BackedLogoSlider";

// ** Css import
import "./styles.css";

const HeroSection = () => {
  return (
    <Box className="hero-section-container">
      <Box className="hero-section-content">
        <LazyLoadImage
          alt="hero image"
          src={HawkImages.HawkCharacter1}
          className="hero-section-image"
          effect="blur"
        />
        <Box className="hero-section-info-container">
          <Box className="hero-section-title-content">
            <Typography className="hero-section-title">
              Launch, Trade and Earn Your Hawk's Eye View of Gaming.
            </Typography>
            <Typography className="hero-section-description">
              The Ultimate Passport to Web3 Gaming. Hawk Terminal accelerating
              for Next-Gen Gamers and Developers
            </Typography>
            <Box
              component="button"
              className="hero-section-launch-app-btn primary-contained-btn"
            >
              <Typography className="launch-app-btn-text">
                Launch App
              </Typography>
              <img
                alt="arrow right icon"
                src={HawkIcons.ArrowRightIcon}
                className="launch-app-btn-icon"
              />
            </Box>
          </Box>
          <Box className="hero-section-barked-buy-list">
            <Typography className="hero-section-barked-text">
              Backed by
            </Typography>
            <BackedLogoSlider />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
