// ** React import
import { useMemo, Fragment } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Icons import
import { BadgeCheck } from "lucide-react";

// Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Types import
import { IFakeInoMetadata } from "../../pages/InoDetailPage/types";

// ** Assets import
import { HawkIcons } from "../../assets";

// ** Css import
import "./styles.css";

export interface SocialLink {
  id: string;
  url: string;
  icon: string;
}

type HawkInoHeaderProps = {
  ino: IFakeInoMetadata;
};

const HawkInoHeader = ({ ino }: HawkInoHeaderProps) => {
  // build ino's social links
  const socialLinks = useMemo(() => {
    const _links: SocialLink[] = [];
    if (ino.x) {
      const _xLink: SocialLink = {
        id: "x-link",
        url: ino.x,
        icon: HawkIcons.XIcon,
      };
      _links.push(_xLink);
    }

    if (ino.discord) {
      const _discordLink: SocialLink = {
        id: "discord-link",
        url: ino.discord,
        icon: HawkIcons.DiscordIcon,
      };
      _links.push(_discordLink);
    }

    if (ino.telegram) {
      const _telegramLink: SocialLink = {
        id: "telegram-link",
        url: ino.telegram,
        icon: HawkIcons.TelegramIcon,
      };
      _links.push(_telegramLink);
    }

    if(ino.web) {
      const _webLink: SocialLink = {
        id: "web-link",
        url: ino.web,
        icon: HawkIcons.ShareNetworkIcon
      }
      _links.push(_webLink)
    }

    return _links;
  }, [ino]);

  const handleOpenLink = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Fragment>
      <LazyLoadImage
        src={ino.backgroundImage}
        alt="ino background image"
        className="hawk-ino-header-ino-bg"
      />
      <Box className="hawk-ino-header-title">
        <LazyLoadImage
          className="hawk-ino-header-image"
          src={ino.avatar}
          effect="blur"
        />
        <Box className="hawk-ino-header-title-content">
          <Box className="hawk-ino-header-ino-info">
            <Typography className="hawk-ino-header-ino-name">
              {ino.name}
            </Typography>
            <Box className="hawk-ino-header-ino-description-box">
              <Typography className="hawk-ino-header-creator">
                {ino.creator}
              </Typography>
              <img src={HawkIcons.CheckIcon} className="hawk-ino-header-seal-checked" />
              <Typography className="hawk-ino-header-description">/</Typography>
              <Typography className="hawk-ino-header-description">
                {`Minted ${ino.minted}/${ino.supply}`}
              </Typography>
            </Box>
            <Box className="hawk-ino-header-tags">
              {ino.tags.map((tag) => (
                <Box key={`ino-tag-${tag}`} className="hawk-ino-header-tag-box">
                  <Typography className="hawk-ino-header-tag">{tag}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="hawk-ino-header-ino-social-links">
            {socialLinks.map((social) => (
              <Box
                key={social.id}
                className="hawk-ino-header-social-btn"
                onClick={() => handleOpenLink(social.url)}
              >
                <img
                  alt="social icon"
                  src={social.icon}
                  className="hawk-ino-header-social-icon"
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default HawkInoHeader;
