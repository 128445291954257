import { Fragment } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";

import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { HawkYellowProgress } from "../../../components/HawkProgress";

import { renderIfElse } from "../../../helpers/render-helper";

// Fake Assets import
import GenkaiAva from "../assets/genkai_ava.png";

import "../index.css";

const StackBox = () => {
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery("(min-width:768px)");

  return (
    <Box className="stack-item">
      {renderIfElse(
        isLargeScreen,
        <Fragment>
          <LazyLoadImage
            src={GenkaiAva}
            effect="blur"
            className="image-avatar"
          />
          <Box className="stack-info-large-scream-box">
            <Box className="stack-info-box-first-col">
              <Box>
                <Typography className="white-text">ARBGAL</Typography>
                <Typography className="inactive-text">
                  Arbigans Galxe
                </Typography>
              </Box>
              <Box>
                <Typography className="inactive-text">STAKE VALUE</Typography>
                <Typography className="white-text">$0</Typography>
              </Box>
            </Box>
            <Box className="stack-info-box-second-col">
              <Box className="stack-info-box-row">
                <Box>
                  <Typography className="inactive-text">INVENTORY</Typography>
                  <Typography className="white-text">1 ARBGAL</Typography>
                  <Typography className="inactive-text">(0.04%)</Typography>
                </Box>
                <Box className="gap-4px-col-box">
                  <Typography className="inactive-text">APR</Typography>
                  <Typography className="gold-text">0.07%</Typography>
                </Box>
                <Box className="gap-8px-col-box">
                  <Typography className="inactive-text">
                    POSITION SPLIT
                  </Typography>
                  <HawkYellowProgress progress={70} height="4px" />
                </Box>
              </Box>
              <Box className="stack-info-box-row">
                <Box>
                  <Typography className="inactive-text">STAKE VALUE</Typography>
                  <Typography className="white-text">$0</Typography>
                </Box>
                <Box className="gap-4px-col-box">
                  <Typography className="inactive-text">APR</Typography>
                  <Typography className="blue-text">1.35%</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            component="button"
            className="manage-button"
            onClick={() => {
              navigate("/staking/dashboard/1");
            }}
          >
            <Typography className="manage-button-text">Manage</Typography>
          </Box>
        </Fragment>,
        <Fragment>
          <Box className="stack-info-small-scream-box">
            <Box
              sx={{ display: "flex", gap: "24px", alignItems: "flex-start" }}
            >
              <LazyLoadImage
                src={GenkaiAva}
                effect="blur"
                className="image-avatar"
              />
              <Box className="stack-info-box-first-col">
                <Box>
                  <Typography className="white-text">ARBGAL</Typography>
                  <Typography className="inactive-text">
                    Arbigans Galxe
                  </Typography>
                </Box>
                <Box>
                  <Typography className="inactive-text">STAKE VALUE</Typography>
                  <Typography className="white-text">$0</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="stack-info-box-second-col">
              <Box className="stack-info-box-row">
                <Box>
                  <Typography className="inactive-text">INVENTORY</Typography>
                  <Typography className="white-text">1 ARBGAL</Typography>
                  <Typography className="inactive-text">(0.04%)</Typography>
                </Box>
                <Box className="gap-4px-col-box">
                  <Typography className="inactive-text">APR</Typography>
                  <Typography className="gold-text">0.07%</Typography>
                </Box>
                <Box className="gap-8px-col-box">
                  <Typography className="inactive-text">
                    POSITION SPLIT
                  </Typography>
                  <HawkYellowProgress progress={70} height="4px" />
                </Box>
              </Box>
              <Box className="stack-info-box-row">
                <Box>
                  <Typography className="inactive-text">STAKE VALUE</Typography>
                  <Typography className="white-text">$0</Typography>
                </Box>
                <Box className="gap-4px-col-box">
                  <Typography className="inactive-text">APR</Typography>
                  <Typography className="blue-text">1.35%</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            component="button"
            className="manage-button"
            onClick={() => {
              navigate("/staking/dashboard/detail");
            }}
          >
            <Typography className="manage-button-text">Manage</Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};
export default StackBox;
