// ** React import
import { useState } from "react";

import { useNavigate } from "react-router-dom";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Components import
import {
  HawkYellowProgress,
  HawkRainbowProgress,
} from "../../../components/HawkProgress";
import PoolLineChart from "./PoolLineChart";
import PoolChartFilter from "./PoolChartFilter";
import PoolRankingTable from "./PoolRakingTable";
import HawkStakingDialog from "../../../components/HawkStakingDialog";

// ** Assets import
import { HawkIcons } from "../../../assets";

// ** Types import
import { ChartFilter } from "../types";

// ** Assets import
import { HawkImages } from "../../../assets";

// ** Css import
import "./styles.css";

import { FakePoolRankingData, FakeHawkPoolDetail } from "../data";

const initialData = [
  { time: "2018-12-22", value: 32.51 },
  { time: "2018-12-23", value: 31.11 },
  { time: "2018-12-24", value: 27.02 },
  { time: "2018-12-25", value: 27.32 },
  { time: "2018-12-26", value: 25.17 },
  { time: "2018-12-27", value: 28.89 },
  { time: "2018-12-28", value: 25.46 },
  { time: "2018-12-29", value: 23.92 },
  { time: "2018-12-30", value: 22.68 },
  { time: "2018-12-31", value: 22.67 },
];

const StakingPoolDetailPage = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<ChartFilter>("15MINUTES");

  const navigate = useNavigate();

  const handleChangeFilter = async (filter: ChartFilter) => {
    setActiveFilter(filter);
  };

  const handleOpenStakingDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseStakingDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box className="pool-detail-view-container">
      <HawkStakingDialog
        open={openDialog}
        handleClose={handleCloseStakingDialog}
        pool={FakeHawkPoolDetail}
      />
      <Box className="pool-detail-view-header">
        <Box
          component="div"
          className="go-black-pool-page-btn"
          onClick={() => navigate(-1)}
        >
          <img
            className="go-black-icon"
            alt="go black icon"
            src={HawkIcons.CaretLeftIcon}
          />
          <Typography className="go-black-btn-text">Black</Typography>
        </Box>
      </Box>
      <Box className="pool-detail-content">
        <Box className="pool-info-section">
          <Box className="pool-info-content">
            <Box className="pool-info-header">
              <Box className="pool-image-container">
                <LazyLoadImage
                  src={FakeHawkPoolDetail.image}
                  alt="pool image"
                  effect="blur"
                  className="pool-detail-view-image"
                />
                <Box className="pool-detail-image-overlay" />
                <img
                  alt="pool badge"
                  src={HawkImages.PoolImageBadge}
                  className="pool-badge-image"
                />
              </Box>
              <Box className="pool-detail-name-box">
                <Typography className="pool-detail-name">
                  {FakeHawkPoolDetail.name}
                </Typography>
                <Typography className="pool-detail-description">
                  {FakeHawkPoolDetail.description}
                </Typography>
              </Box>
              <Box
                component="div"
                className="pool-detail-stake-btn"
                onClick={handleOpenStakingDialog}
              >
                <Typography className="pool-detail-stake-btn-text">
                  Stake
                </Typography>
              </Box>
            </Box>
            <Box className="pool-info-body">
              <Box className="pool-stat-info-row">
                <Box className="pool-stat-info-coll">
                  <Typography className="pool-stat-info-title">
                    INVENTORY APR
                  </Typography>
                  <Typography className="pool-inventory-value-text">
                    {0.07}%
                  </Typography>
                </Box>
                <Box className="pool-stat-info-coll">
                  <Typography className="pool-stat-info-title">
                    LIQUIDITY APR
                  </Typography>
                  <Typography className="pool-liquidity-value-text">
                    {1.35}%
                  </Typography>
                </Box>
                <Box className="pool-stat-info-coll">
                  <Typography className="pool-stat-info-title">TVL</Typography>
                </Box>
              </Box>
              <Box className="pool-stat-info-row">
                <Box className="pool-stat-info-coll">
                  <Typography className="pool-stat-info-title">
                    SPOT PRICE
                  </Typography>
                </Box>
                <Box className="pool-position-slit-coll">
                  <Typography className="pool-stat-info-title">
                    POSITION SPLIT
                  </Typography>
                  <HawkRainbowProgress progress={90} />
                </Box>
              </Box>
              <Box className="pool-staking-slipt-row">
                <Typography className="pool-stat-info-title">
                  STAKING SPLIT SPLIT
                </Typography>
                <HawkYellowProgress progress={70} />
              </Box>
            </Box>
          </Box>
          <Box className="pool-detail-tables-container">
            <Box className="pool-ranking-box">
              <Typography className="pool-ranking-title">
                Top inventory stackers
              </Typography>
              <PoolRankingTable data={FakePoolRankingData} />
            </Box>
            <Box className="pool-ranking-box">
              <Typography className="pool-ranking-title">
                Top liquidity stackers
              </Typography>
              <PoolRankingTable data={FakePoolRankingData} />
            </Box>
          </Box>
        </Box>
        <Box className="pool-stake-summary-section">
          <Box className="pool-chart-container">
            <PoolChartFilter
              activeFilter={activeFilter}
              handleChangeFilter={handleChangeFilter}
            />
            <PoolLineChart data={initialData} />
          </Box>
          <Box className="pool-stake-container">
            <Box className="pool-stake-content-section">
              <Box className="pool-stake-header-row">
                <Box className="pool-stake-header-left-box">
                  <Typography className="pool-stake-primary-text">
                    Rewards
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    $ARBGAL
                  </Typography>
                </Box>
                <Box className="pool-stake-header-right-box">
                  <Typography className="pool-stake-badge-text">
                    30 days
                  </Typography>
                </Box>
              </Box>
              <Box className="pool-stake-stat-row">
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    TOTAL
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0.7 $ARBGAL
                  </Typography>
                </Box>
              </Box>
              <Box className="pool-stake-stat-row">
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    REDEM
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0 $ARBGAL
                  </Typography>
                </Box>
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    MINT
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0.7 $ARBGAL
                  </Typography>
                </Box>
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    SWAP
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0 $ARBGAL
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="pool-stake-divider-box" />
            <Box className="pool-stake-content-section">
              <Typography className="pool-stake-primary-text">
                Activity
              </Typography>
              <Box className="pool-stake-stat-row">
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    BUY
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0
                  </Typography>
                </Box>
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    SELLS
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    7
                  </Typography>
                </Box>
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    SWAPS
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0
                  </Typography>
                </Box>
                <Box className="pool-stake-stat-coll">
                  <Typography className="pool-stake-primary-text">
                    DEPOSITS
                  </Typography>
                  <Typography className="pool-stake-secondary-text">
                    0
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StakingPoolDetailPage;
