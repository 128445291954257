// ** React Import
import { useState, useMemo, MouseEvent, Fragment } from "react";

// ** MUI Import
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

// ** Assets import
import { HawkIcons } from "../../assets";

// ** Types import
import {
  ActiveActionFilterOpt,
  CollectionStatusFilterOpt,
  CollectionStatus,
  ActivityAction,
} from "../../pages/StakingActivityPage/types";

// ** Css import
import "./styles.css";

const collectionFilterOpts: CollectionStatusFilterOpt[] = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "In Active",
    value: "IN_ACTIVE",
  },
];

const actionFilterOpts: ActiveActionFilterOpt[] = [
  {
    label: "Buy",
    value: "BUY",
  },
  {
    label: "Sell",
    value: "SELL",
  },
  {
    label: "Stake",
    value: "STAKE",
  },
  {
    label: "Un Stake",
    value: "UNSTAKE",
  },
];

type CollectionFilterProps = {
  activeFilter: CollectionStatus;
  handleChangeFilter: (filter: CollectionStatus) => void;
};

export const StakingActivityCollectionFilter = ({
  activeFilter,
  handleChangeFilter,
}: CollectionFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openCollectionFilterMenu = Boolean(anchorEl);

  const activeFilterOpt = useMemo(() => {
    const _filter = collectionFilterOpts.find(
      (filter) => filter.value === activeFilter
    );

    return _filter;
  }, [activeFilter]);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeCollectionFilter = async (filter: CollectionStatus) => {
    try {
      handleChangeFilter(filter);
      handleCloseMenu();
    } catch (error) {}
  };
  return (
    <Fragment>
      <Box
        id="activity-collection-filter-box"
        component="button"
        aria-haspopup="true"
        aria-expanded={openCollectionFilterMenu ? "true" : undefined}
        aria-controls={
          openCollectionFilterMenu ? "activity-collection-opts-menu" : undefined
        }
        className="activity-active-filter-box"
        onClick={handleOpenMenu}
      >
        {activeFilterOpt ? (
          <Fragment>
            <Typography className="activity-active-filter-label">
              {activeFilterOpt.label}
            </Typography>
            <img
              src={HawkIcons.CaretDownIcon}
              className={`activity-filter-menu-icon ${
                openCollectionFilterMenu ? "rotate-icon" : ""
              }`}
            />
          </Fragment>
        ) : (
          <Typography className="active-pools-filter-label">
            Filter buy collection
          </Typography>
        )}
      </Box>
      <Menu
        id="activity-collection-opts-menu"
        anchorEl={anchorEl}
        open={openCollectionFilterMenu}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              backgroundImage: "none",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "activity-collection-filter-box",
          style: {
            padding: 0,
            paddingTop: "4px",
            background: "transparent",
          },
        }}
      >
        <Box className="activity-filter-opts-container">
          {collectionFilterOpts.map((opt) => {
            const isActiveOpt = opt.value === activeFilter;
            return (
              <Box
                component="div"
                className={
                  isActiveOpt
                    ? "active-activity-filter-opt"
                    : "activity-filter-opt"
                }
                onClick={() => handleChangeCollectionFilter(opt.value)}
              >
                <Typography className="active-pools-filter-label">
                  {opt.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Menu>
    </Fragment>
  );
};

type ActivityActionFilterProps = {
  activeFilter: ActivityAction;
  handleChangeFilter: (filter: ActivityAction) => void;
};

export const StakingActivityActionFilter = ({
  activeFilter,
  handleChangeFilter,
}: ActivityActionFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openActionFilterMenu = Boolean(anchorEl);

  const activeFilterOpt = useMemo(() => {
    const _filter = actionFilterOpts.find(
      (filter) => filter.value === activeFilter
    );

    return _filter;
  }, [activeFilter]);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeActionFilter = (filter: ActivityAction) => {
    try {
      handleChangeFilter(filter);
      handleCloseMenu();
    } catch (error) {}
  };
  return (
    <Fragment>
      <Box
        id="activity-action-filter-box"
        component="button"
        aria-haspopup="true"
        aria-expanded={openActionFilterMenu ? "true" : undefined}
        aria-controls={
          openActionFilterMenu ? "activity-action-opts-menu" : undefined
        }
        className="activity-active-filter-box"
        onClick={handleOpenMenu}
      >
        {activeFilterOpt ? (
          <Fragment>
            <Typography className="activity-active-filter-label">
              {activeFilterOpt.label}
            </Typography>
            <img
              src={HawkIcons.CaretDownIcon}
              className={`activity-filter-menu-icon ${
                openActionFilterMenu ? "rotate-icon" : ""
              }`}
            />
          </Fragment>
        ) : (
          <Typography className="active-pools-filter-label">
            Filter by action
          </Typography>
        )}
      </Box>
      <Menu
        id="activity-action-opts-menu"
        anchorEl={anchorEl}
        open={openActionFilterMenu}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              backgroundImage: "none",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "activity-action-filter-box",
          style: {
            padding: 0,
            paddingTop: "4px",
            background: "transparent",
          },
        }}
      >
        <Box className="activity-filter-opts-container">
          {actionFilterOpts.map((opt) => {
            const isActiveOpt = opt.value === activeFilter;
            return (
              <Box
                component="div"
                className={
                  isActiveOpt
                    ? "active-activity-filter-opt"
                    : "activity-filter-opt"
                }
                onClick={() => handleChangeActionFilter(opt.value)}
              >
                <Typography className="active-pools-filter-label">
                  {opt.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Menu>
    </Fragment>
  );
};
