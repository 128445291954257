// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import HawkProgress from "../HawkProgress";

// ** Helpers import
import { renderNumber } from "../../helpers/render-helper";

// ** Assets Import
import { HawkIcons } from "../../assets";

// ** Css import
import "./styles.css";

const InoMintingNotify = () => {
  return (
    <Box className="collection-minting-notify">
      <Typography className="collection-minting-notify-title">
        Minting is Over!
      </Typography>
      <Box className="collection-minting-progress-container">
        <Box className="collection-minting-progress-label-box">
          <Typography className="collection-minting-progress-label">{`${50}% minted`}</Typography>
          <Typography className="collection-minting-progress-label">
            {renderNumber(1500)}/{3000}
          </Typography>
        </Box>
        <HawkProgress progress={50} />
      </Box>
      <Box component={"button"} className="explore-collection-btn">
        <Typography className="explore-collection-btn-text">
          Explore collection
        </Typography>
        <img
          alt="explore icon"
          src={HawkIcons.ArrowSquareBlack}
          className="explore-collection-btn-icon"
        />
      </Box>
    </Box>
  );
};

export default InoMintingNotify;
