// ** React import
import { Routes, Route, BrowserRouter } from "react-router-dom";

import HawkTheme from "./themes";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// ** Layout import
import MainLayout from "./layouts/MainLayout";
import StakingLayout from "./layouts/StakingLayout";

// ** Pages import
import HomePage from "./pages/HomePage";
import InoDetailPage from "./pages/InoDetailPage";
import CollectionPage from "./pages/CollectionPage";
import StakingPoolsPage from "./pages/StakingPoolsPage";
import StakingMyItemsPage from "./pages/StakingMyItemsPage";
import StakingActivityPage from "./pages/StakingActivityPage";
import StakingDashboardPage from "./pages/StakingDashboardPage";
import StakingPoolDetailPage from "./pages/StakingPoolsPage/StakingPoolDetailPage";
import StakingDashboardDetail from "./pages/StakingDashboardPage/components/StakingDashboardDetail";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={createTheme(HawkTheme("dark"))}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/igo" element={<HomePage />} />
            <Route path="/ino/:tokenId" element={<InoDetailPage />} />
            <Route path="/collection" element={<CollectionPage />} />
            <Route path="/staking" element={<StakingLayout />}>
              <Route path="/staking" element={<StakingDashboardPage />} />
              <Route path="dashboard" element={<StakingDashboardPage />} />
              <Route path="dashboard/:id" element={<StakingDashboardDetail />} />
              <Route path="my-items" element={<StakingMyItemsPage />} />
              <Route path="pools" element={<StakingPoolsPage />} />
              <Route path="pools/:id" element={<StakingPoolDetailPage />} />
              <Route path="activity" element={<StakingActivityPage />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
