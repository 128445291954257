// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Countdown, { CountdownRenderProps } from "react-countdown";

// ** Css import
import "./styles.css";

type InoStageCountdownProps = {
  startTitle: string;
  endTitle: string;
  startDate: string | Date;
  endDate: string | Date;
  onStartEvent: () => void;
  onEndEvent: () => void;
};

const InoStageCountdown = ({
  startTitle,
  endTitle,
  startDate,
  endDate,
  onStartEvent,
  onEndEvent,
}: InoStageCountdownProps) => {
  const [isStartEvent, setIsStartEvent] = useState<boolean>(false);

  const CountdownToStartRender = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      return (
        <Countdown
          date={endDate}
          renderer={CountdownToEndRender}
          onComplete={onEndEvent}
        />
      );
    }

    return (
      <Box className="ino-stage-countdown-content">
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{days}</Typography>
          <Typography className="ino-detail-secondary-text">Days</Typography>
        </Box>
        <Typography className="time-countdown-divider-text">:</Typography>
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{hours}</Typography>
          <Typography className="ino-detail-secondary-text">Hours</Typography>
        </Box>
        <Typography className="time-countdown-divider-text">:</Typography>
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{minutes}</Typography>
          <Typography className="ino-detail-secondary-text">Mins</Typography>
        </Box>
        <Typography className="time-countdown-divider-text">:</Typography>
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{seconds}</Typography>
          <Typography className="ino-detail-secondary-text">Secs</Typography>
        </Box>
      </Box>
    );
  };

  const CountdownToEndRender = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      return (
        <Typography className="ino-detail-white-text">
          Event completed
        </Typography>
      );
    }

    return (
      <Box className="ino-stage-countdown-content">
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{days}</Typography>
          <Typography className="ino-detail-secondary-text">Days</Typography>
        </Box>
        <Typography className="time-countdown-divider-text">:</Typography>
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{hours}</Typography>
          <Typography className="ino-detail-secondary-text">Hours</Typography>
        </Box>
        <Typography className="time-countdown-divider-text">:</Typography>
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{minutes}</Typography>
          <Typography className="ino-detail-secondary-text">Mins</Typography>
        </Box>
        <Typography className="time-countdown-divider-text">:</Typography>
        <Box className="ino-stage-time-box">
          <Typography className="ino-stage-time-value">{seconds}</Typography>
          <Typography className="ino-detail-secondary-text">Secs</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="ino-time-count-down-row">
      <Typography className="ino-detail-secondary-text">
        {isStartEvent ? endTitle : startTitle}
      </Typography>
      <Countdown
        date={startDate}
        renderer={CountdownToStartRender}
        onComplete={() => {
          setIsStartEvent(true);
          onStartEvent();
        }}
      />
    </Box>
  );
};

export default InoStageCountdown;
