import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// ** Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import HawkCreateVaultDialog from "../HawkCreateVaultDialog";

// ** Fake Assets import
import ItemAvatarImage from "./images/avatar-image.png";
import ItemWallPaperImage from "./images/wallpaper.png";

// ** Css import
import "./styles.css";

const HawkMyItemCard = () => {
  return (
    <Box className="item-card-container">
      <LazyLoadImage
        src={ItemWallPaperImage}
        effect="blur"
        className="item-card-wall-paper-image"
      />
      <Box sx={{ transform: "translateY(-32px)" }}>
        <Box className="item-detail-card-header-box">
          <Box className="item-avatar-image-box">
            <LazyLoadImage
              src={ItemAvatarImage}
              className="item-avatar-image"
              effect="blur"
            />
            <Box className="item-avatar-image-overlay" />
          </Box>
          <Typography className="item-name">ARBGAL</Typography>
        </Box>
        <Box className="item-cars-card-info-box">
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className="item-card-divider"
          />
          <Box className="item-card-info-group">
            <Box className="item-card-info-row">
              <Typography className="item-card-info-title">
                Combined APR
              </Typography>
              <Typography className="item-card-info-value">1.42%</Typography>
            </Box>
            <Box className="item-card-info-row">
              <Typography className="item-card-info-title">TVL</Typography>
              <Typography className="item-card-info-value">$1,123</Typography>
            </Box>
            <Box className="item-card-info-row">
              <Typography className="item-card-info-title">
                Liquidity
              </Typography>
              <Box className="item-card-liquidity-status" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const HawkEmptyItemCard = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box className="empty-item-card-container">
      <HawkCreateVaultDialog
        open={openDialog}
        handleClose={handleCloseDialog}
      />
      <Box className="empty-item-card-info-box">
        <Typography className="empty-item-card-title">
          Not seeing a collection?
        </Typography>
        <Typography className="empty-item-card-description-text">
          Unlock yield of your NFTs by creating an NFTX vault.
        </Typography>
        <Box component="button" className="refetch-balance-btn">
          <Typography className="refetch-balance-btn-text">
            Refetch balances
          </Typography>
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        flexItem
        sx={{
          margin: 0,
          borderColor: "rgba(255, 255, 255, 0.1)",
        }}
      />
      <Box className="empty-item-card-btn-group">
        <Button variant="outlined" className="empty-item-card-learn-more-btn">
          Learn more
        </Button>
        <Box
          component="button"
          className="empty-item-card-create-vault-btn"
          onClick={handleOpenDialog}
        >
          <Typography className="empty-item-card-create-vault-btn-text">
            Create Vault
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HawkMyItemCard;
