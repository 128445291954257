import HawkAppBarLogo from "./images/app-logo.svg";
import HawkFooterLogo from "./images/footer-logo.svg";

import XIcon from "./icons/x.svg";
import SnowIcon from "./icons/snow.png";
import InfoIcon from "./icons/info.svg";
import TagIcon from "./icons/rocket.svg";
import RocketIcon from "./icons/rocket.svg";
import SearchIcon from "./icons/search.svg";
import PlusIcon from "./icons/plus-icon.svg";
import MenuIcon from "./icons/menu-icon.svg";
import DiscordIcon from "./icons/discord.svg";
import CloseIcon from "./icons/close-icon.svg";
import TelegramIcon from "./icons/telegram.svg";
import ChartBarIcon from "./icons/chart-bar.svg";
import CheckIcon from "./icons/checked-icon.svg";
import CaretDownIcon from "./icons/caret-down.svg";
import RefreshIcon from "./icons/refresh-icon.svg";
import CurrencyIcon from "./icons/currency-icon.svg";
import ArrowRightIcon from "./icons/arrow-right.svg";
import ChartLineIcon from "./icons/chart-line-up.svg";
import CrownSimpleIcon from "./icons/crown-simple.svg";
import CaretLeftIcon from "./icons/caret-left-white.svg";
import ShareNetworkIcon from "./icons/share-network.svg";
import CheckMarkIcon from "./icons/checked-mark-icon.svg";
import ClockCountdownIcon from "./icons/clock-countdown.svg";
import ArrowSquareShare from "./icons/arrow-square-share.svg";
import ArrowSquareBlack from "./icons/arrow-square-black.svg";
import ArrowRightGreenIcon from "./icons/arrow-right-green.svg";


import HawkCharacter1 from "./images/hawk-character1.png";
import HawkCharacter2 from "./images/hawk-character2.png";
import HawkCharacter3 from "./images/hawk-character3.png";
import HawkCharacter4 from "./images/hawk-character4.png";
import HawkCharacter5 from "./images/hawk-character5.png";

import PoolImageBadge from "./images/pool-img-badge.png";

const HawkIcons = {
  XIcon,
  TagIcon,
  MenuIcon,
  InfoIcon,
  PlusIcon,
  SnowIcon,
  CheckIcon,
  CloseIcon,
  RocketIcon,
  SearchIcon,
  RefreshIcon,
  DiscordIcon,
  CurrencyIcon,
  TelegramIcon,
  CaretDownIcon,
  ChartLineIcon,
  ChartBarIcon,
  CaretLeftIcon,
  CheckMarkIcon,
  ArrowRightIcon,
  CrownSimpleIcon,
  ArrowSquareBlack,
  ArrowSquareShare,
  ShareNetworkIcon,
  ClockCountdownIcon,
  ArrowRightGreenIcon,
};

const HawkImages = {
  HawkCharacter1,
  HawkCharacter2,
  HawkCharacter3,
  HawkCharacter4,
  HawkCharacter5,
  HawkAppBarLogo,
  HawkFooterLogo,
  PoolImageBadge,
};

export { HawkIcons, HawkImages };
