import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmptyStake = () => {
	let navigate = useNavigate();
	return (
		<Box className='empty-stake-container'>
			<Typography>Oops, you have no staked positions...</Typography>
			<Box sx={{display: 'flex', alignItems: 'center', gap: '20px', mt: '20px'}}>
				<Button variant='outlined' className='check-my-item-button' onClick={()=>{
					navigate('/staking/my-items')
				}}>
					Check My Items
				</Button>
				<Typography className='inactive-text-color'>
					OR
				</Typography>
				<Box className='manage-button'>
					<Typography className="manage-button-text">Explore Pools</Typography>
				</Box>
			</Box>
		</Box>
	)
}
export default EmptyStake
