// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import HawkMyItemCard, {
  HawkEmptyItemCard,
} from "../../components/HawkMyItemCard";
import HawkEmptyItems from "./components/HawkEmptyItems";

import { renderIfElse } from "../../helpers/render-helper";

import "./styles.css";

const StakingMyItemsPage = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  return (
    <Box className="staking-my-items-page">
      <Box className="staking-my-items-page-header">
        <Typography className="staking-my-items-page-title">
          My Items
        </Typography>
      </Box>
      {renderIfElse(
        false,
        <HawkEmptyItems />,
        <Swiper
          onSwiper={setSwiper}
          spaceBetween={16}
          slidesPerView="auto"
          resizeObserver
          centeredSlides={false}
          onBeforeDestroy={() => setSwiper(null)}
        >
          <SwiperSlide className="item-detail-card-slice">
            <HawkMyItemCard />
          </SwiperSlide>
          <SwiperSlide className="item-detail-card-slice">
            <HawkMyItemCard />
          </SwiperSlide>
          <SwiperSlide className="item-detail-card-slice">
            <HawkMyItemCard />
          </SwiperSlide>
          <SwiperSlide className="item-detail-card-slice">
            <HawkMyItemCard />
          </SwiperSlide>
          <SwiperSlide className="item-detail-card-slice">
            <HawkMyItemCard />
          </SwiperSlide>
          <SwiperSlide className="item-detail-card-slice">
            <HawkEmptyItemCard />
          </SwiperSlide>
        </Swiper>
      )}
    </Box>
  );
};

export default StakingMyItemsPage;
