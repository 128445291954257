// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";

// ** Components import
import HawkPoolsTable from "../../components/HawkPoolsTable";
import HawkPoolsFilter from "../../components/HawkPoolsFilter";

// ** Types import
import { PoolFilter } from "./types";

// ** Assets import
import { HawkIcons } from "../../assets";

// ** Css import
import "./styles.css";

// ** Fake Data
import { FakeHawkPools } from "./data";

const StakingPoolsPage = () => {
  const [activeFilter, setActiveFilter] = useState<PoolFilter>("TVL");

  const handleChangeFilter = async (filter: PoolFilter) => {
    setActiveFilter(filter);
  };

  return (
    <Box className="staking-pools-page">
      <Box className="staking-pools-header-row">
        <Box className="staking-pools-header-left-side">
          <Typography className="staking-pools-header-title">Pools</Typography>
          <Box component="button" className="create-pool-btn">
            <img
              alt="plus icon"
              src={HawkIcons.PlusIcon}
              className="create-pool-btn-icon"
            />
            <Typography className="create-pool-btn-text">New Pool</Typography>
          </Box>
        </Box>
        <Box className="staking-pools-header-right-side">
          <Box className="hawk-pools-search-box">
            <img
              alt=""
              src={HawkIcons.SearchIcon}
              className="hawk-pools-search-icon"
            />
            <InputBase
              fullWidth
              placeholder="Search..."
              inputProps={{
                style: {
                  color: "#fff",
                  fontSize: "14px",
                  lineHeight: "20px",
                  opacity: 0.5,
                },
              }}
              className="hawk-pools-search-input"
            />
          </Box>
          <HawkPoolsFilter
            activeFilter={activeFilter}
            handleChangeFilter={handleChangeFilter}
          />
        </Box>
      </Box>
      <HawkPoolsTable pools={FakeHawkPools} />
    </Box>
  );
};

export default StakingPoolsPage;
