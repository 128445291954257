// ** MUI import
import Box from "@mui/material/Box";

// ** Swiper libs import
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

// ** Types import
import { IFakeInoMetadata } from "../../pages/InoDetailPage/types";

// ** Css import
import "./styles.css";

type HawkInoImagesGalleryProps = {
  ino: IFakeInoMetadata;
};

const HawkInoImagesGallery = ({ ino }: HawkInoImagesGalleryProps) => {
  const pagination = {
    clickable: true,
  };

  return (
    <Swiper
      pagination={pagination}
      modules={[Pagination]}
      className="hawk-ino-images-gallery"
    >
      {ino.images.map((image, index) => (
        <SwiperSlide
          key={`swiper-image-slice-${index}`}
          className="hawk-ino-gallery-image-slice"
        >
          <img alt="ino image" src={image} className="hawk-ino-gallery-image" />
          <Box className="image-overlay" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HawkInoImagesGallery;
