// ** React Import
import { useState, useMemo, MouseEvent, Fragment } from "react";

// ** MUI Import
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

// ** Types import
import { ChartFilter, IChartFilterOption } from "../types";

// ** Css import
import "./styles.css";
import { HawkIcons } from "../../../assets";

const poolFilterOpts: IChartFilterOption[] = [
  {
    label: "15 Minutes",
    value: "15MINUTES",
  },
  {
    label: "24 Hours",
    value: "24HOURS",
  },
  {
    label: "7 Days",
    value: "7DAYS",
  },
  {
    label: "30 Days",
    value: "30DAYS",
  },
];

type PoolChartFilterProps = {
  activeFilter: ChartFilter;
  handleChangeFilter: (filter: ChartFilter) => Promise<void>;
};

const PoolChartFilter = ({
  activeFilter,
  handleChangeFilter,
}: PoolChartFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openChartFilterMenu = Boolean(anchorEl);

  const activeFilterOpt = useMemo(() => {
    const _filter = poolFilterOpts.find(
      (filter) => filter.value === activeFilter
    );

    return _filter;
  }, [activeFilter]);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        id="chart-active-filter-box"
        component="button"
        aria-haspopup="true"
        aria-expanded={openChartFilterMenu ? "true" : undefined}
        aria-controls={
          openChartFilterMenu ? "chart-filter-opts-menu" : undefined
        }
        className="chart-active-filter-box"
        onClick={handleOpenMenu}
      >
        {activeFilterOpt ? (
          <Fragment>
            <Typography className="chart-filter-label">
              {activeFilterOpt.label}
            </Typography>
            <img
              alt="caret icon"
              src={HawkIcons.CaretDownIcon}
              className={`toggle-menu-icon ${
                openChartFilterMenu ? "rotate-icon" : ""
              }`}
            />
          </Fragment>
        ) : (
          <Typography className="active-chart-filter-label">
            Select a filter
          </Typography>
        )}
      </Box>
      <Menu
        id="chart-filter-opts-menu"
        anchorEl={anchorEl}
        open={openChartFilterMenu}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              backgroundImage: "none",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "chart-active-filter-box",
          style: {
            padding: 0,
            paddingTop: "4px",
            background: "transparent",
          },
        }}
      >

      </Menu>
    </Fragment>
  );
};

export default PoolChartFilter;
