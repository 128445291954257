// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import {
  StakingActivityActionFilter,
  StakingActivityCollectionFilter,
} from "../../components/StakingActivityFilter";

import HawkStakingActivityTable from "../../components/HawkStakingActivitiesTable";

// ** Types import
import { CollectionStatus, ActivityAction } from "./types";

// ** Css import
import "./styles.css";

import { FakeStakingActivities } from "./data";

const StakingActivityPage = () => {
  const [collectionStatus, setCollectionStatus] =
    useState<CollectionStatus>("ACTIVE");
  const [activityAction, setActivityAction] = useState<ActivityAction>("BUY");

  const handleChangeCollectionStatus = (status: CollectionStatus) => {
    setCollectionStatus(status);
  };

  const handleChangeActivityAction = (action: ActivityAction) => {
    setActivityAction(action);
  };

  return (
    <Box className="staking-activity-page">
      <Typography className="activity-page-title">Activity</Typography>
      <Box className="activity-filters-box">
        <Box sx={{ flex: 1 }}>
          <StakingActivityCollectionFilter
            activeFilter={collectionStatus}
            handleChangeFilter={handleChangeCollectionStatus}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <StakingActivityActionFilter
            activeFilter={activityAction}
            handleChangeFilter={handleChangeActivityAction}
          />
        </Box>
      </Box>
      <HawkStakingActivityTable activities={FakeStakingActivities} />
    </Box>
  );
};

export default StakingActivityPage;
