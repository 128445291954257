// ** React import
import { Fragment, useMemo } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import ImageList from "@mui/material/ImageList";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";

// Helpers components import
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ** Helpers import
import { renderIf } from "../../helpers/render-helper";

// ** Types import
import { IFakeHawkWalletAsset } from "../../pages/StakingPoolsPage/types";

// ** Css import
import "./styles.css";

// ** Assets
import { HawkIcons } from "../../assets";
import ItemImage from "./images/wallet-item.png";

const items: IFakeHawkWalletAsset[] = [
  {
    id: 1,
    image: ItemImage,
  },
  {
    id: 2,
    image: ItemImage,
  },
  {
    id: 3,
    image: ItemImage,
  },
  {
    id: 4,
    image: ItemImage,
  },
];

type HawkWalletAssetsProps = {
  selectedItems: number[];
  handleRemoveAllItem: () => void;
  handleSelectItems: (ids: number[]) => void;
  handleToggleSelectItem: (id: number) => void;
};

const HawkWalletAssets = ({
  selectedItems,
  handleSelectItems,
  handleRemoveAllItem,
  handleToggleSelectItem,
}: HawkWalletAssetsProps) => {
  const itemIds = useMemo(() => {
    return items.map((item) => item.id);
  }, [items]);

  return (
    <Box className="hawk-wallet-assets-container">
      <Box className="wallet-assets-header-info">
        <Typography className="wallet-asset-header-title">
          Choose your Assets ({items.length})
        </Typography>
        <Box className="refresh-wallet-asset-btn">
          <Typography className="refresh-wallet-asset-btn-text">
            Refresh wallet
          </Typography>
          <img
            src={HawkIcons.RefreshIcon}
            alt="refresh icon"
            className="refresh-wallet-btn-icon"
          />
        </Box>
      </Box>
      <Box className="wallet-assets-tool-bar-row">
        <Box className="wallet-assets-tool-bar-btn-group">
          <Typography className="selecting-item-info-text">
            {selectedItems.length} Selecting
          </Typography>
          <Box
            component="div"
            className="staking-dialog-toolbar-btn"
            onClick={() => handleSelectItems(itemIds)}
          >
            <Typography className="staking-dialog-toolbar-btn-text">
              Select all
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="staking-dialog-divider"
          />
          <Box
            component="div"
            className="staking-dialog-toolbar-btn"
            onClick={handleRemoveAllItem}
          >
            <Typography className="staking-dialog-toolbar-btn-text">
              Remove all
            </Typography>
          </Box>
        </Box>
        <Box className="staking-dialog-toolbar-search-box">
          <img
            src={HawkIcons.SearchIcon}
            className="staking-dialog-toolbar-search-box-icon "
          />
          <InputBase
            fullWidth
            placeholder="Search ID"
            inputProps={{
              style: {
                color: "#fff",
                fontSize: "14px",
                lineHeight: "20px",
                opacity: 0.5,
              },
            }}
            className="staking-dialog-toolbar-search-box-text"
          />
        </Box>
      </Box>
      <Grid container columnSpacing="16px" rowSpacing="16px">
        {items.map((item) => {
          const isSelectedItem = selectedItems.includes(item.id);
          return (
            <Box
              component="div"
              key={`wallet-item-${item.id}`}
              onClick={() => handleToggleSelectItem(item.id)}
              className="wallet-image-item-box"
            >
              {renderIf(
                isSelectedItem,
                <Fragment>
                  <Box className="wallet-item-overlay-border" />
                  <Box className="active-item-checked-mark">
                    <img
                      alt="checked icon"
                      src={HawkIcons.CheckMarkIcon}
                      className="active-item-checked-icon"
                    />
                  </Box>
                </Fragment>
              )}
              <LazyLoadImage
                src={item.image}
                effect="blur"
                className="wallet-item-image"
              />
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default HawkWalletAssets;
