import { useState } from 'react'
import { TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Tab, Typography} from "@mui/material";
import './currnet.sales.css'
import InoPackage from "./InoPackage";
import EastIcon from '@mui/icons-material/East';
import ComingSoonPackage from "./ComingSoonPackage";
import { Swiper, SwiperSlide } from 'swiper/react';
import HalloweenBg from './assets/halloween.png'
import SpaceBg from './assets/space.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
function CurrentUpComingSales() {
	const [tab, setTab] = useState("1")
//@ts-ignore
	const handleChangeTab = (event: SyntheticEvent, tab: string) => {
		setTab(tab);
	};
	
	return (
		<Box className='main-container'>
			<Typography className='current-upcoming-text'>Current & Upcoming Sales</Typography>
			<small className='sub-text-coming'>Providing the most outstanding investment opportunities in the market, we believe that fairness and transparency are key factors.</small>
			<TabContext value={tab}>
				<Box className='tab-size'>
					<TabList
						TabIndicatorProps={{
							sx: {
								backgroundColor: '#C8FF00', // Indicator (line) color
							},
						}}
						variant="fullWidth"
						onChange={handleChangeTab}
						aria-label="lab API tabs example"
						sx={{
							display: 'flex',
							width: '100%', // Make the TabList take the full width of its container
							justifyContent: 'space-between', // Distribute space between tabs
						}}
						className='tablist'
					>
						<Tab
							label={
								<Typography className="active-ino-tab">
									INO
								</Typography>
							}
							sx={{
								fontWeight: '900',
								color: '#FFFFFF80',
								textAlign: 'center', // Ensure text is centered
								'&.Mui-selected': {
									color: 'white',
									backgroundColor: 'rgba(200,255,0,0.1)',
									borderTopLeftRadius: '5px',
									borderTopRightRadius: '5px',
								},
							}}
							value="1"
						/>
						<Tab
							disabled
							className="space-grotesk-font"
							label={
								<Box
									sx={{
										gap: '10px',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Typography className="space-grotesk-font">
										IDO
									</Typography>
									<Box className="coming-soon-btn">
										Coming Soon
									</Box>
								</Box>
							}
							sx={{
								fontWeight: '900',
								textAlign: 'center', // Ensure text is centered
								color: '#FFFFFF80',
								'&.Mui-selected': {
									color: 'white',
									backgroundColor: 'rgba(200,255,0,0.1)',
									borderTopLeftRadius: '5px',
									borderTopRightRadius: '5px',
								},
							}}
							value="2"
						/>
					</TabList>
				</Box>
				<TabPanel value="1" sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
					<Swiper
						className='wrap-packages-container'
						spaceBetween={10}
						slidesPerView={3}
						onResize={(swiper:any) => {
							swiper.update(); // Update Swiper on resize
						}}
						centeredSlides={false}
						breakpoints={{
							// when the screen width is >= 640px, set slidesPerView to 1
							0: { slidesPerView: 1.1 },
							470: { slidesPerView: 1.5 },
							// when the screen width is >= 768px, set slidesPerView to 2
							600: { slidesPerView: 2.5 },
							// when the screen width is >= 1024px, set slidesPerView to 3
							768: { slidesPerView: 3 },
						}}
						scrollbar={{ draggable: true }}
						onSlideChange={() => console.log('slide change')}
						// onSwiper={(swiper) => console.log(swiper)}
					>
						<SwiperSlide><InoPackage imgSrc={HalloweenBg}/></SwiperSlide>
						<SwiperSlide><InoPackage imgSrc={SpaceBg}/></SwiperSlide>
						<SwiperSlide><ComingSoonPackage/></SwiperSlide>
					</Swiper>
					<Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
						<Typography className='show-more-text'>Show more</Typography>
						<EastIcon sx={{color: '#C8FF00'}}/>
					</Box>
					<Box className='glow-img'></Box>
				</TabPanel>
				<TabPanel value="2">
				</TabPanel>
			</TabContext>
		</Box>
	)
}

export default CurrentUpComingSales
