import {Box, Button, Typography} from "@mui/material";
import Countdown from 'react-countdown';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";
import Avatar from './assets/avatar.png'

import { HawkIcons } from "../../assets";

const InoPackage = (props:{
	imgSrc: string
}) =>{
	const navigate = useNavigate();

	// @ts-ignore
	const renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {
		if (completed) {
			return ""
		} else {
			return <Typography className='countdown-text'>{days}d {hours}h {minutes}m {seconds}s</Typography>;
		}
	};
	return (
		<Box className='ino-box-container ino-box-container-available'>
			<Box sx={{display: 'flex', justifyContent: 'center',position: 'relative'}}>
				<LazyLoadImage
					alt="Icon Package"
					src={props.imgSrc}
					effect="blur"
					className='image-banner'
				/>
				<Box className='attribute-container'>
					<Typography className='small-btn-field'>Gamified</Typography>
					<Typography className='small-btn-field'>Launch Platform</Typography>
					<Typography className='small-btn-field'>Layer-2</Typography>
				</Box>
			</Box>
			<Box className='name-io-container'>
				<LazyLoadImage
					alt="Icon Package"
					src={Avatar}
					effect="blur"
					className='small-image'
				/>
				<Typography className='space-grotesk-font name-io-text' sx={{color: 'white'}}>Name.io</Typography>
			</Box>
			<hr className='package-line'/>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="line up chart" src={HawkIcons.ChartLineIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Total Raise</Typography>
				</Box>
				<Typography className='text-package-description'>$300,000</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="clock icon" src={HawkIcons.ClockCountdownIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Ends In</Typography>
				</Box>
				<Button className='countdown-container'>
					<Countdown date='2025-02-01T01:02:03' renderer={renderer}/>
				</Button>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="tag icon" src={HawkIcons.TagIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Sales Type</Typography>
				</Box>
				<Typography className='text-package-description'>Ape Launch</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="bar chart" src={HawkIcons.ChartBarIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Market Marker</Typography>
				</Box>
				<Typography className='text-package-description'>TBA</Typography>
			</Box>
			<hr className='package-line'/>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="rocket" src={HawkIcons.RocketIcon} className="current-sale-title-icon" />
					<Typography className='text-package-title'>Backed By</Typography>
				</Box>
				<Typography className='text-package-description'>N/A</Typography>
			</Box>
			<Box className='info-row'>
				<Box className="current-sale-title-box">
					<img alt="crown simple icon" src={HawkIcons.CrownSimpleIcon} className="current-sale-title-icon"/>
					<Typography className='text-package-title'>Highlights</Typography>
				</Box>
				<Typography className='text-package-description'>N/A</Typography>
			</Box>
			<Box sx={{display: 'flex', justifyContent: 'center', mt: '30px', mb: '30px'}}>
				<Button className='join-now-btn' onClick={() => navigate('/ino/1')}>
					<Typography className='join-now-text'>
						Join Now!
					</Typography>
				</Button>
			</Box>
		</Box>
	)
}

export default InoPackage
