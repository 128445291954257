import Typography from "@mui/material/Typography";
import './about.css'
import Box from "@mui/material/Box";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Grid from '@mui/material/Grid2';
import TextGlow from '../../HawkUpComingSale/assets/text-glow.png'
const DetailAbout = () =>{
	const testData = [
		{title: "Launch Price", price: "0.005", unit: "USDC"},
		{title: "Total Amount", price: "250,000", unit: "USDC"},
		{title: "Total Issued", price: "50,000,000", unit: "PAI"},
		{title: "Total Wining Tickets", price: "1000", unit: ""},
		{title: "Redemption Amount", price: "250", unit: "USDC per Ticket"},
		{title: "Total Participants", price: "270,115", unit: ""},
	]
	return (
		<>
			<Box className='about-box'>
				<Typography className='about-title'>About CyberKongz</Typography>
				<Typography className='about-description'>
					Founded by the artist Myoo in March 2021, CyberKongz started as a unique and randomly generate collection of 1000, 34x34 pixelated NFT gorillas now known as the Genesis Collection. CyberKongz were the first to introduce and popularize a number of innovative NFT mechanics, such as unique tokenomics for their utility token $BANANA, which led to breeding and Babies. The VX collection, 3D avatars designed for the metaverse and gaming are the most interoperable avatar of any Web3 project.
					https://www.cyberkongz.com/about
				</Typography>
			</Box>
			<LazyLoadImage
				alt="Icon Package"
				src={TextGlow}
				effect="blur"
				className='about-image'
			/>
			<Box className='about-line'/>
			<Box className='about-box'>
				<Typography className='about-title'>INO Detail</Typography>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					{
						testData.map((detail:{title:string, price:string, unit:string})=>(
							<Grid key={`ino-detail-box-${detail.title}`} size={4} className='ino-detail-box custom-grid-ino-box'>
								<Typography className='ino-detail-title'>{detail.title}</Typography>
								<Box className='ino-detail-currency'>
									<Typography className='ino-detail-price'>{detail.price}</Typography>
									<Typography className='ino-detail-unit'>
										{detail.unit}
									</Typography>
								</Box>
							</Grid>
						))
					}
				</Grid>
			</Box>
			<Box className='about-line'/>
			<Box className='about-box'>
				<Typography className='about-title'>Overview</Typography>
				<Typography className='about-description'>
					Aavegotchi is an NFT collectibles game that uses Aave’s interest-bearing aTokens as DeFi collateral to mint NFT avatars. Players aim to increase the value of their Aavegotchi NFTs by “farming rarity” in various gamified ways on-chain.				</Typography>
			</Box>
			<Box className='about-line'/>
			<Box className='about-box'>
				<Typography className='about-title'>Term & Condition</Typography>
				<Typography className='about-description'>
					1. Participants must have at least $200 worth of USDC tokens (EVM chains) in their connected wallet. The more you engage on socials, the greater the chances of winning. Create an account to make the checkout process during the lottery phase smoother.
				</Typography>
				<Typography className='about-description' sx={{marginTop: '20px'}}>
					2. To redeem allocated IDO tokens, participants will need to pay blockchain gas fees in some cases, which may vary due to network conditions.
				</Typography>
				<Typography className='about-description' sx={{marginTop: '20px'}}>
					3. If you have any questions, please submit a ticket on Telegram.
				</Typography>
				<Typography className='about-description' sx={{marginTop: '20px'}}>
					4. In order to be eligible to receive IDO tokens, please check & fulfil all snapshot requirements established by each IDO project.
				</Typography>
				<Typography className='about-description' sx={{marginTop: '20px'}}>
					5. Disclaimer: The information and project data provided above are sourced from the project or public information and are for reference only.
				</Typography>
			</Box>
		</>
	)
}

export default DetailAbout
