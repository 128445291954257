import {Typography, Box, TextField} from "@mui/material";
import { IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {HawkIcons} from "../../../assets";
import './activity.css'
interface ActivityObj {
	mintedBy: string,
	quantity: string | number,
	amount: string | number,
	time: string,
}
const Activity = () => {
	const tempData = [
		{mintedBy: "567esgd..43454", quantity: "5", amount: "145", time: "4 months ago"},
		{mintedBy: "567esgd..43454", quantity: "5", amount: "145", time: "4 months ago"},
		{mintedBy: "567esgd..43454", quantity: "5", amount: "145", time: "4 months ago"},
	]
	return (
		<>
			<Box className='activity-container'>
				<Box sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<Typography className='staking-text'>Your Staking</Typography>
					<Box className='pagination-container'>
						<IconButton aria-label="left"sx={{
							borderRadius: '5px',
							padding: 0.75,
							backgroundColor: '#FFFFFF1A'
						}}>
							<KeyboardArrowLeftIcon />
						</IconButton>
						<Typography className='inactive-text-color'>Go to page</Typography>
						<TextField type='number'  sx={{
							width: '60px', // Square width
							'& .MuiOutlinedInput-root': {
								borderRadius: '10px', // Remove border radius for square shape
							},
							'& input': {
								textAlign: 'center', // Ensures the text is centered within the input box
							},
						}}/>
						<Typography className='inactive-text-color'>of 12</Typography>
						<IconButton aria-label="right" sx={{
							borderRadius: '5px',
							padding: 0.75,
							backgroundColor: '#FFFFFF1A'
							
						}}>
							<KeyboardArrowRightIcon />
						</IconButton>
					</Box>
				</Box>
				<Box sx={{mt:3}}>
					<TableContainer component={Paper} className='table-style'>
						<Table>
							<TableHead>
								<TableRow className='background-table-header'>
									<TableCell className='inactive-text-color'>Minted By</TableCell>
									<TableCell className='inactive-text-color'>Quantity</TableCell>
									<TableCell className='inactive-text-color'>Total amount</TableCell>
									<TableCell className='inactive-text-color'>Time</TableCell>
									<TableCell className='inactive-text-color'></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									tempData && tempData.map((item:ActivityObj, index)=>(
										<TableRow key={`activity-row-${index}`} className='background-table-row'>
											<TableCell>{item.mintedBy}</TableCell>
											<TableCell>{item.quantity}</TableCell>
											<TableCell>{item.amount}</TableCell>
											<TableCell>{item.time}</TableCell>
											<TableCell><img src={HawkIcons.ArrowSquareShare}/></TableCell>
										</TableRow>
									))
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</>
	)
}
export default Activity
